import * as angular from 'angular';
import * as jQuery from "jquery";

"use strict";

angular.module('FieldModule')
       .controller('SourceController', SourceController);

// Injection of each class
SourceController.$inject = ['$scope', 'ScopeAndLocal', 'scanStation'];

// Controller Constructor
function SourceController($scope, ScopeAndLocal, scanStation) {

    let vm = this;

    vm.$onInit = async function () {
        try {

            ScopeAndLocal.startVariable(vm, $scope, 'fc');
            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            ScopeAndLocal.startVariable(vm, $scope, 'row');
            ScopeAndLocal.startVariable(vm, $scope, 'processField');

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This will make sure to load the external job keyword as one of
     * the field controller initialParams.
     *
     * @param keyword
     */
    vm.loadExternalJobKeyword = async (keyword) => {
        try {
            if (keyword !== undefined && keyword.length >= 3) {
                let data = await scanStation.getExternalJobsByKeyWord(keyword);
                vm.fc.initialFieldParams.externalJobs = data.data.data;
                $scope.$apply();
            }
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This method is to help the source controller to find the scanStationID
     */
    vm.getScanStationID = (scanStationController = undefined) => {

        let scanStationID = false;

        if(vm.fc.initialFieldParams !== undefined && vm.fc.initialFieldParams.dropdownFromStationLookUp !== undefined
          && vm.fc.initialFieldParams.dropdownFromStationLookUp.stationId !== undefined){
            scanStationID = vm.fc.initialFieldParams.dropdownFromStationLookUp.stationId;
        }

        if (vm.fc !== undefined && vm.fc.scanStationId !== undefined){
            scanStationID = vm.fc.scanStationId;
        }

        // if we cant find on field controller initial params or the variable, we must get from
        // the current value of the scan station controller id.
        if (scanStationID == false && scanStationController !== undefined && scanStationController.id !== undefined){
            scanStationID = scanStationController.id;
        }

        return scanStationID;
    }

    /**
     * This will be the method that will know how to get the job id.
     * @param scanStationController
     */
    vm.getJobId = (scanStationController = undefined) => {

        let jobId = false;

        // this must be the case for a selected job id
        if (vm.fc !== undefined && vm.fc.currentJobId !== undefined){
            jobId = vm.fc.currentJobId;
        }

        // if we cant find on field controller initial params or the variable, we must get from
        // the current value of the scan station controller id.
        if (jobId == false && scanStationController !== undefined && scanStationController.jobID !== undefined){
            jobId = scanStationController.jobID
        }

        return jobId;
    }

    /**
     * This will load the
     * @param scanStationController
     * @param initialParams
     */
    vm.loadJobScanStations = async function (scanStationController, initialParams) {
        try
        {
            let scanStationId = vm.getScanStationID(scanStationController);
            let jobId = vm.getJobId(scanStationController);

            vm.fc.fieldParameterToProcess = vm.processField;
            vm.fc.currentJobId = scanStationController.jobID;

            // Loading all scan station fields by the scan station id
            vm.fc.getScanStationFields(scanStationId);

            // this will load the the station id and job id from the initial params
            if(initialParams !== undefined && initialParams[vm.processField]  !== undefined){
                jobId = initialParams[vm.processField].jobId
                scanStationId = initialParams.dropdownFromStationLookUp.stationId
                vm.fc.initialFieldParams[vm.fc.fieldParameterToProcess].stationId = scanStationId;
            }

            let data = await scanStation.getJobScanStations(jobId, null);
            vm.fc.jobScanStations = data.data.data;

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This is the action of select a module.
     * @param job
     */
    vm.selectModule = async (job, fieldParameterToProcess) => {
        try
        {
            // this conditional is to make sure that we have the object before the next job.id assign of value.
            if(vm.fc.initialFieldParams[fieldParameterToProcess] == undefined){
                vm.fc.initialFieldParams[fieldParameterToProcess] = {}
            }

            // add the job id and load bloc options
            vm.fc.initialFieldParams[fieldParameterToProcess].jobId=job.id;
            vm.fc.getJobScanStations(fieldParameterToProcess); // all bloc items

            // Restarting the externalJobs and updating with the job.title name on the keyword
            vm.fc.initialFieldParams.externalJobs={};
            vm.fc.initialFieldParams.externalJobKeyword=job.title

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This is the action of select a module.
     * @param job
     */
    vm.changeModule = async (externalJobKeyword, fieldParameterToProcess) => {
        try
        {
            if(externalJobKeyword.length == 0) {
                // add the job id and load bloc options
                vm.fc.initialFieldParams[fieldParameterToProcess].jobId = vm.scanStation.scanStationObj.jobID;
                vm.fc.getJobScanStations(fieldParameterToProcess); // all bloc items

                // Restarting the externalJobs and updating with the job.title name on the keyword
                vm.fc.initialFieldParams.externalJobs={};
                vm.fc.initialFieldParams.externalJobKeyword="";
            } else {

                if (externalJobKeyword.length >= 3) {
                    let data = await scanStation.getExternalJobsByKeyWord(externalJobKeyword);
                    vm.fc.initialFieldParams.externalJobs = data.data.data;
                    $scope.$apply();
                }
            }


        } catch (e) {
            console.log(e);
        }
    }

}

export default SourceController;