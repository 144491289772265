import * as angular from 'angular';
import jQuery from "jquery";
import ModuleController from '../controllers/module.controller'

"use strict";

angular.module('ModuleModule')
    .directive('expansibleDirective', expansibleDirective);

expansibleDirective.$inject = [];

function expansibleDirective(Configuration) {
    let directive = this;

    return ({
        controller: ModuleController,
        restrict: "A",
        scope: {
            data: '<',
            main: '<',
        },
        link: function (scope, element, attributes) {

            let elementClass = "." + attributes.class;
            let currentElement = jQuery(element);
            let parentElement = jQuery(elementClass).parent();


            // default minus class tag
            if( !jQuery(element).hasClass('fa-minus') && !jQuery(element).hasClass('fa-plus')){
                jQuery(element).addClass('fa-plus')
            }

            // action for element click
            currentElement.click((e) => {

                let sibblings = jQuery(currentElement).parent().parent().find('.hide-field, .show-field');
                jQuery(sibblings).each((index, el) => {
                    if (jQuery(el).hasClass('hide-field') || jQuery(el).hasClass('show-field')) {
                        jQuery(el).toggleClass('hide-field')
                        jQuery(el).toggleClass('show-field')
                    }
                })

                if(jQuery(currentElement).hasClass('fas')){
                    if(jQuery(currentElement).hasClass('fa-minus') || jQuery(currentElement).hasClass('fa-plus')){
                        jQuery(currentElement).toggleClass('fa-minus')
                        jQuery(currentElement).toggleClass('fa-plus')
                    }
                }

            });
        }
    });
}