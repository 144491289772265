import * as angular from 'angular';

"use strict";

angular.module('PageBarModule')
       .controller('pageBarController', PageBarController);

PageBarController.$inject = ['$scope'];

// Controller Constructor
function PageBarController($scope)
{
  // Creating the view model reference
  let vm = this;
  vm.scanStation = '';
  vm.data = '';
  vm.main = '';

  vm.$onInit = function () {
    try
    {
      $scope.scanStation = vm.scanStation;
      $scope.data = vm.data;
      $scope.main = vm.main;

    } catch (e) {
      console.log(e);
    }

  }

  vm.showRevision = function () {
    try
    {
      return vm.main.checkCartolyticsCustomerShowOption('show_revision_on_station',false);
    } catch (e) {
      console.log(e);
    }

  }

}

export default PageBarController;