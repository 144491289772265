import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";
import countController from "../count/controllers/count.controller";

"use strict";

class countDataComponent extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/scan-station-components/count';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        action: '@',
        scanStation: '=',
        data: '=',
        field: '='
    }

    // This will set what will be the controller of this component
    public controller : any = countController;

    // This will set an alias
    public controllerAs: string = 'count';


}

angular.module('FieldModule').component('countData', <StandardComponent> new countDataComponent());