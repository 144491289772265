import * as angular from 'angular';
import * as $ from "jquery";

"use strict";

angular
    .module('FieldModule')
    .factory('PlainDataFieldFactory', PlainDataFieldFactory);


PlainDataFieldFactory.$inject = [];


/**
 * Object that will create a Plain Data Factory, all methods of factory
 * will be available from outside.
 */
function PlainDataFieldFactory() {
    let factory = this;

    /**
     * Method responsible for setting the field parameters from edit
     * @param fieldParameters
     * @returns {*}
     */

    factory.setInitialFieldParametersFromEdit = function (fieldParameters) {

        var parametersToReturn = '';
        angular.forEach(fieldParameters, function(fieldParam) {
            if(fieldParam.scan_station_field_parameter == 'plainDataFieldValue') {
                parametersToReturn = fieldParam.parameter_value;
            }
        });

        return parametersToReturn;
    }

    return factory;
}

