import * as angular from 'angular';
import PermissionScanStationController from "../controllers/permission.scanStation.controller";
import $ from 'jquery';

"use strict";

angular.module('PermissionModule')
       .directive('showMoreData', showMoreDataDirective);

/**
 * Directive that will build the show more.
 *
 * @returns
 * @constructor
 */
function showMoreDataDirective() {

    let directive = this;

    return ({
        templateUrl: 'components/permission/template/show-more.html',
        controller: PermissionScanStationController,
        controllerAs: 'psc',
        restrict: 'EA',
        scope: {
            main: '<',
            scanStation: '<',
            data: '<',
            row: '<',
            rowKey: '<',
        },
        link : function($scope, element, attributes, controller)  {

            let shift = 16;
            let ctrl = 17;
            let eKEY = 69;
            let dKEY = 68;
            let sKEY = 83;
            let insertKEY = 45;

            // this will capture the esc action
            $(document).on('keydown', function (event) {
                if(event.which === 27)
                {
                    let closeButton = angular.element(document.querySelectorAll(".scan-station-header-cancel-viewing"))
                    closeButton.triggerHandler('click')
                    event.preventDefault();
                }
            });

            // This will get the ctrl + s (to show) to expand the show edit links and data filtering
            $(document).on('keydown', function (event) {
                if(event.ctrlKey == true && event.which == sKEY ){
                    event.preventDefault();
                    $('#showEditLinksAndDataFiltering').trigger('click');
                }
            });

            // This will be getting the ctrl + e (to edit) to edit a field
            $(document).on('keydown', function (event) {
                if(event.ctrlKey == true && event.which == eKEY){
                    event.preventDefault();
                    if($('.header-title').html() == 'Viewing'){
                        $('.can-trigger-edit .editLinkButton i').trigger('click');
                    }
                }
            });

            if (attributes.showMoreData === 'link'){
                element.bind('click', function(event) {
                    event.preventDefault();
                    angular.element(element).find('a').triggerHandler('click');
                });
            }

            // this is for the TH to change from the template to the attribute text
            if (attributes.showMoreData !== 'link') {
                element[0].innerHTML = attributes.showMoreData
            }
            controller.showMoreData() ? element.css('display', 'table-cel') : element.css('display', 'none');
        }
        
    });
}