import * as angular from 'angular';

"use strict";

angular
    .module('ScanStationModule')
    .factory('ScanStationFactory', ScanStationFactory)
    .config(ScanStationConfiguration);

/**
 * Object that will set aHrefSanitizationWhitelist for requests
 * in this factory.
 *
 * @param $compileProvider
 * @constructor
 */
function ScanStationConfiguration($compileProvider) {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|file|blob):/);
}

ScanStationFactory.$inject = ['$http', 'Configuration'];

/**
 * Object that will create a Grid Factory, all methods of factory
 * will be available from outside.
 *
 * @param $http
 * @param GridSemaphore
 * @param Configuration
 * @param MissingScanStationFieldsExceptionFactory
 * @param ArgumentUndefinedExceptionFactory
 * @param SuperuserService
 * @constructor
 */
function ScanStationFactory($http, Configuration) {

    let factory = this;

    /**
     * This will be responsible to transform string into booleans.
     * @param string
     */
    factory.stringToBoolean = (string) => {

        if(string === undefined || string === false){
            return false;
        }

        switch(string.toLowerCase().trim()){
            case "true": case "yes": case "1": return true;
            case "false": case "no": case "0": case null: return false;
            default: return Boolean(string);
        }
    }

    /**
     * This will be responsible to get the lookup data
     * @param location
     */
    factory.getLookupData = async (location = 'lookup_data') => {

        let apiBase =  Configuration.getApiUrl()  + 'get-lookup-data';
        let config = {
            params: {
                'location' : location,
            }
        };

        let response = await  $http.get(apiBase, config);

        return response.data;
    };

    /**
     * This will be responsible to load the scan station fields,
     * by informing the scan station ID, it will go to the backend and
     * load all the fields related to this scan station id.
     *
     * @param scanStationID
     */
    factory.loadScanStationFields = async (scanStationID) => {

        let scanStationFields = []
        let apiBase =  Configuration.getApiUrl()  + 'load-scan-station-fields';

        let data = {
            id: scanStationID,
        };

        let response = await $http.post(apiBase, data);

        // checking if contain data, if does must populate, otherwise will return an empty array
        if(response.data !== undefined && response.data.fields != undefined){
            scanStationFields = response.data.fields
        }

        return scanStationFields;
    };

    /**
     * This will return the quantity of fields of the current scan station,
     * this is to avoid to load more than once the scan station fields if the count are the
     * same quantity.
     * #
     * @param scanStationID
     */
    factory.getCountScanStationFields = async (scanStationID) => {

        let count = 0;
        let apiBase =  Configuration.getApiUrl()  + 'get-count-scan-station-fields';

        let data = {
            id: scanStationID,
        };

        let response = await $http.post(apiBase, data);

        // checking if contain data, if does must populate, otherwise will return an empty array
        if(response.data !== undefined && response.data.count != undefined){
            count = response.data.count
        }

        return count;
    };


    return factory;
}

