import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
    .service('qrcodeField', qrcodeField);

/**
 * Service constructor.
 * @constructor
 */
function qrcodeField() {
    let service = this;


    /**
     * Returns the initial Parameters for this field, to be distinguished from other parameters the field may have in the future after creating it
     */
    service.getInitialParams = async function () {
        try
        {
            let initialParams = {
                'qrcodeButtonText': {
                    'validation' : {
                        'required': false,
                    }
                },
                'qrcodeSoundAfterScan': {
                    'validation' : {
                        'required': false,
                    }
                },
            }

            return initialParams;

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This will filter QRCode URL's
     * @param data
     */
    service.filterQrCodeHttp = (data) => {

        let filtered = data;

        // This will be responsible to catch all http/https links from the QR Code Scanner
        const regexHttp = new RegExp("(?<http>https?:\\/\\/(:?www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b(:?[-a-zA-Z0-9@:%_\\+.~#?&//=]*))");

        // This is checking if exist the http(s) in it, so if we have it, it will be loading the url into filtered
        if (regexHttp.test(filtered)) {
            let matches = filtered.match(regexHttp);
            filtered = matches.groups != null ? matches.groups.http : matches[0];
        }

        return filtered;

    }

}