import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";
import ResultsController from "components/scanstation/components/results/controllers/results.controller";

"use strict";

class Results extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/scanstation/components/results';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        scanStation: '=',
        main: '=',
        data: '=',
    }

    // This will set what will be the controller of this component
    public controller : any = ResultsController;

    // This will set an alias
    public controllerAs: string = 'results';


}

angular.module('ScanStationModule').component('scanStationResults', <StandardComponent> new Results);