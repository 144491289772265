import * as angular from 'angular';
import * as $ from "jquery";
import {type} from "os";

"use strict";

angular
    .module('FieldModule')
    .factory('fieldValidation', fieldValidation);


fieldValidation.$inject = ['fieldService'];


/**
 * Object that will create a Field Validation Factory, all methods of factory
 * will be available from outside.
 */
function fieldValidation(fieldService) {

    let factory = this;

    // Validate the field upon creating or editing to ensure
    factory.validateInitialFieldParams = async function (fieldType, intialFieldParamsProvided) {

        let defaultInitialFieldParams = await fieldService.getInitialParams(fieldType);

        let responseString = '';

        // loop through main parameters and check for required parameters
        // Inside this loop we must repeat the same process to check required sub parameters
        // No field paramters should go deeper than one level
        angular.forEach(defaultInitialFieldParams, function (parameterObject, parameterKey) {

                if (typeof(intialFieldParamsProvided[parameterKey]) != 'undefined' ) {

                    // for checking where an array requires at least entry
                    if (parameterObject.validation.required == true && parameterObject.validation.requiredType == 'listMustHaveOne') {
                        if (intialFieldParamsProvided[parameterKey].length == 0) {
                            responseString += parameterObject.validation.validationResponse + '\n';
                        }
                    }

                    if (parameterObject.validation.required == true && parameterObject.validation.requiredType == 'stringMustNotBeEmpty') {
                        if (intialFieldParamsProvided[parameterKey] == '' || intialFieldParamsProvided[parameterKey] == null ) {
                            responseString += parameterObject.validation.validationResponse + '\n';
                        }
                    }

                    // repeat the same for sub parameters
                    if(typeof(parameterObject.subParameters) != 'undefined') {

                        angular.forEach(parameterObject.subParameters, function(subParameterObject, subParameterKey) {

                            if (typeof(intialFieldParamsProvided[parameterKey][subParameterKey]) != 'undefined' ) {

                                // for checking where an array requires at least entry
                                if (subParameterObject.validation.required == true && subParameterObject.validation.requiredType == 'listMustHaveOne') {
                                    if (intialFieldParamsProvided[parameterKey][subParameterKey].length == 0) {
                                        responseString += parameterObject.validation.validationResponse + '\n';
                                    }
                                }

                                if (subParameterObject.validation.required == true && subParameterObject.validation.requiredType == 'stringMustNotBeEmpty') {
                                    if (intialFieldParamsProvided[parameterKey][subParameterKey] == '' || intialFieldParamsProvided[parameterKey][subParameterKey] == null) {
                                        responseString += parameterObject.validation.validationResponse + '\n';
                                    }
                                }
                            } else if (subParameterObject.validation.required == true) {
                                responseString += subParameterObject.validation.validationResponse + '\n';
                            }

                        });

                    }
                } else if (parameterObject.validation.required == true) {
                    responseString += parameterObject.validation.validationResponse + '\n';
                }

        });


        if (responseString != '') {
            return responseString;
        } else {
            return true;
        }


    }

    return factory;
}

