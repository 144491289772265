import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";
import GetLatestDataController from "./controllers/get-latest-data.controller";

"use strict";

class GetLatestDataComponent extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/sockets/components/getLatestData';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        scanStation: '=',
        main: '=',
        data: '=',
    }

    // This will set what will be the controller of this component
    public controller : any = GetLatestDataController;

    // This will set an alias
    public controllerAs: string = 'getLatestData';
}

angular.module('SocketsModule').component('socketsGetLatestData', <StandardComponent> new GetLatestDataComponent);
