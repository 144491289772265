import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";
import ScanStationFieldController from "./controllers/scanStationField.controller";

"use strict";

class ScanStationFieldComponent extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/field/components/scanStationField';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        fc: '<',
        grid: '<',
        scanStation: '<',
        field: '<',
        main: '<',
    }

    // This will set what will be the controller of this component
    public controller : any = ScanStationFieldController;

    // This will set an alias
    public controllerAs: string = 'ssf';
}

angular.module('FieldModule').component('scanStationField', <StandardComponent> new ScanStationFieldComponent);