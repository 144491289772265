import * as angular from 'angular';

"use strict";

angular.module('SidebarPagesModule')
       .directive('sidebarPagesDirective', sidebarPagesDirective)
       .service('SidebarPagesService', SidebarPagesService);

/**
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function sidebarPagesDirective() {
    let directive = this;
    return ({
        controller: "SidebarPagesController",
        restrict: "A",
    });
}

/**
 * Service constructor.
 * @constructor
 */
function SidebarPagesService() {
    let service = this;
}
