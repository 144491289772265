import * as angular from 'angular';

"use strict";

angular
    .module('LoginAttemptsModule')
    .factory('LoginAttemptsFactory', LoginAttemptsFactory)
    .config(LoginAttemptsConfiguration);


/**
 * Object that will set aHrefSanitizationWhitelist for requests
 * in this factory.
 *
 * @param $compileProvider
 * @constructor
 */
function LoginAttemptsConfiguration($compileProvider: any) {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|file|blob):/);
}

LoginAttemptsFactory.$inject = ['$http', 'Configuration'];

function LoginAttemptsFactory($http, Configuration) {

    let factory = this

    /**
     * 
     * @param maxAttempts 
     * @returns {*}
     */
    factory.setLoginAttempts = function (maxAttempts: number) {
        let apiBase = Configuration.getApiUrl() + 'set-inactivity-timeout';
        let body = {
            settingKey: 'max_login_attempts',
            settingValue: maxAttempts
        }
        return $http.post(apiBase, body);
    }

    /**
     * 
     * @param timeout 
     * @returns {*}
     */
    factory.setAttemptTimeout = function (timeout: number) {
        let apiBase = Configuration.getApiUrl() + 'set-inactivity-timeout';
        let body = {
            settingKey: 'login_attempts_timeout',
            settingValue: timeout
        }
        return $http.post(apiBase, body);
    }


    factory.updateInactivitySettingFromLocalStorage = function (maxAttempts: number, timeout: number) {

        let appSettingParsed = JSON.parse(localStorage.getItem('appSettings'));

        angular.forEach(appSettingParsed.data, function (settingEntry) {
            if (settingEntry.setting_key == 'max_login_attempts') {
                settingEntry.setting_value = maxAttempts;
            }
            if (settingEntry.setting_key == 'login_attempts_timeout') {
                settingEntry.setting_value = timeout;
            }
        })

        localStorage.setItem('appSettings', JSON.stringify(appSettingParsed))

    }

    return factory
}