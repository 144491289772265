import * as angular from 'angular';

"use strict";

angular.module('PageModule')
    .directive('pageDirective', pageDirective)
    .service('PageService', PageService);

/**
 * Directive object that will build the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function pageDirective() {

    let directive = this;
    return ({
        controller: "PageController",
        restrict: "A",
    });
}

/**
 * Service constructor.
 * @constructor
 */
function PageService() {
    let service = this;
}