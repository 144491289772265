import * as angular from 'angular';
import * as $ from "jquery";

"use strict";

angular
    .module('FieldModule')
    .factory('dropdownFromListFactory', dropdownFromListFactory);


dropdownFromListFactory.$inject = [];


/**
 * Object that will create a Dropdown From List Factory, all methods of factory
 * will be available from outside.
 */
function dropdownFromListFactory() {
    let factory = this;

    /**
     * Method responsible for building and editing the list for the dropdown
     * @param dropdownFromList
     * @param action
     * @param dropdownItem
     * @param index
     * @returns {*}
     */
    factory.addOrRemoveFromDropDownList = function (dropdownFromList, action, dropdownItem, index = null) {
        if((dropdownItem == '' || typeof dropdownItem == 'undefined') && action == 'add') {
            alert('Please Enter a dropdown item');
            return dropdownFromList;
        }
        if(typeof dropdownFromList == 'undefined') {
            dropdownFromList = {};
        }

        if(action == 'add') {

            dropdownFromList[Object.keys(dropdownFromList).length] = dropdownItem;

        } else {
            delete dropdownFromList[index];
            // build new object with fresh keys to keep it in sync
            var dropdownFromListForLoop = dropdownFromList;
            dropdownFromList = {};
            var i = 0;
            angular.forEach(dropdownFromListForLoop, function(value) {
                dropdownFromList[i] = value;
                i++;
            });
        }

        $('#new-dropdown-item').trigger('focus');



        return dropdownFromList;

    }

    /**
     * Method responsible for setting the field parameters from edit
     * @param fieldParameters
     * @returns {*}
     */

    factory.setInitialFieldParametersFromEdit = function (fieldParameters) {

        var parametersToReturn = {};
        angular.forEach(fieldParameters, function(fieldParam) {
            if(fieldParam.scan_station_field_parameter == 'dropdownFromList') {
                parametersToReturn = JSON.parse(fieldParam.parameter_value);
            }
        });

        return parametersToReturn;
    }

    return factory;
}

