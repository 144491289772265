import * as angular from 'angular';
import PageBarController from "./controllers/pagebar.controller";

"use strict";

let PageBarComponent =  {
    templateUrl: 'components/page-bar/template/page-bar.html',
    controller: PageBarController,
    controllerAs: 'pbc',
    bindings: {
        scanStation: '<',
        data: '<',
        main: '<',
    }
}

angular.module('PageBarModule')
       .component('pageBar', PageBarComponent);
