import * as angular from 'angular';

"use strict";

angular.module('PermissionModule')
       .controller('PermissionSwitchController', PermissionSwitchController);

PermissionSwitchController.$inject = ['$scope'];

// Controller Constructor
function PermissionSwitchController($scope) {

    // Creating the view model reference
    let vm = this;
    vm.scanStation = '';
    vm.main = '';
    vm.to = '';
    vm.data = '';

    vm.$onInit = function () {
        try {

            $scope.scanStation = vm.scanStation;
            $scope.main = vm.main;
            $scope.to = vm.to;
            $scope.data = vm.data;

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Validation for the switch to edit mode, at the actual scenario it must have:
     * 1 - have a user role admin
     * 2 - can't be a shared_station
     */
    vm.switchToEditMode = () => {

        let response = false;

        // if you arent an admin you must not be able to see it
        if(!vm.main.userRoles.includes('admin')){
            return response;
        }

        // if the scan station is a shared station, you shouldn't be able to see it
        if (vm.scanStation.scanStationObj !== undefined &&
            vm.scanStation.scanStationObj.stationDetails !== undefined &&
            vm.scanStation.scanStationObj.stationDetails.shared_station !== undefined &&
            vm.scanStation.scanStationObj.stationDetails.shared_station == true){
            return response;
        }

        // if the scan station is not in your own instance then you should not be able to see it
        if (
            vm.main.cartolyticsCustomerID!=vm.scanStation.scanStationObj.stationDetails.cartolytics_customer_id
        ){
            return response;
        }

        switch ( vm.scanStation.stateName ){
            case 'bloc':
            case 'scan-station':
                response = true;
                break;

            case 'edit-bloc':
            case 'scan-station-edit':
                response = false;
                break;

            default:
                console.log('[Switch-To-Edit] [Missing stateName] ' + vm.scanStation.stateName)
        }

        return response;

    }

    /**
     * Validate if can be switching to frontend.
     */
    vm.switchToFrontend = () => {

        let response = false;

        switch ( vm.scanStation.stateName ){
            case 'bloc':
            case 'scan-station':
                response = false;
                break;

            case 'edit-bloc':
            case 'scan-station-edit':
                response = true;
                break;

            default:
                console.log('[Switch-To-Frontend] [Missing stateName] ' + vm.scanStation.stateName)
        }

        return response;
    }

}

export default PermissionSwitchController;