import * as angular from 'angular';

"use strict";

angular.module('PermissionModule')
       .service('ScopeAndLocal', ScopeAndLocalService);

ScopeAndLocalService.$inject = [];

/**
 * Directive that will build the show more.
 *
 * @returns
 * @constructor
 */
function ScopeAndLocalService() {

    let service = this;
    service.debug = false;

    service.startVariable = function (vm, $scope, variable) {

        if($scope[variable] !== undefined && vm[variable] === undefined) {
            vm[variable] = $scope[variable];
        } else if(vm[variable] !== undefined){
            $scope[variable] = vm[variable];
        }

        if(service.debug == true){
            console.log({
                '$scope' : $scope[variable],
                'vm' : vm[variable],
                'variable' : variable,
            })
        }
    }

    service.existValue = (obj, attribute) => {

        let exist = false;

        if (typeof obj != 'undefined' && typeof obj[attribute] != 'undefined') {
            exist = true;
        }

        return exist;

    }

    service.$get = function () {

        return {
            startVariable: service.startVariable,
        }

    };

}