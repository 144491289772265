import * as angular from 'angular';

"use strict";

angular.module('RolesAndPermissionsModule')
    .service('RolesAndPermissionsService', RolesAndPermissionsService);



/**
 * Service constructor.
 * @constructor
 */
function RolesAndPermissionsService() {
    let service = this;
}