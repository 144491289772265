import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
       .controller('TextfieldController', TextfieldController);

// Injection of each class
TextfieldController.$inject = ['$scope', 'ScopeAndLocal'];

// Controller Constructor
function TextfieldController($scope, ScopeAndLocal) {

    let vm = this;

    vm.$onInit = async function () {
        try {
            ScopeAndLocal.startVariable(vm, $scope, 'action');
            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            ScopeAndLocal.startVariable(vm, $scope, 'fc');
            ScopeAndLocal.startVariable(vm, $scope, 'field');
            ScopeAndLocal.startVariable(vm, $scope, 'row');

        } catch (e) {
            console.log(e);
        }
    }

}

export default TextfieldController;