import * as angular from 'angular';
import * as $ from "jquery";

"use strict";

angular
    .module('FieldModule')
    .factory('FormListFieldFactory', FormListFieldFactory);


FormListFieldFactory.$inject = ['fieldService'];


/**
 * Object that will create a Plain Data Factory, all methods of factory
 * will be available from outside.
 */
function FormListFieldFactory(fieldService) {
    let factory = this;

    /**
     * Method responsible for building and editing the sub form child-parent Array
     * @param formListLocalRemoteFieldsArray
     * @param action
     * @param localField
     * @param remoteField
     * @param index
     * @returns {*}
     */

    factory.addOrFormListLocalRemoteFields = function (formListLocalRemoteFieldsArray, action, localField, remoteFieldSlug, index = null) {

        if((localField == '' || typeof localField == 'undefined') && action == 'add') {
            alert('Please Select a Parent Field');
            return formListLocalRemoteFieldsArray;
        }

        if((remoteFieldSlug == '' || typeof remoteFieldSlug == 'undefined') && action == 'add') {
            alert('Please type a remote field slug to update');
            return formListLocalRemoteFieldsArray;
        }

        if(typeof formListLocalRemoteFieldsArray == 'undefined') {
            formListLocalRemoteFieldsArray = {};
        }

        if(action == 'add') {
            localField = JSON.parse(localField);
            formListLocalRemoteFieldsArray[Object.keys(formListLocalRemoteFieldsArray).length] = {
                'localFieldId' : localField.id,
                'remoteFieldSlug' : remoteFieldSlug,
                'localFieldName' : localField.field_name,
            };

        } else {
            delete formListLocalRemoteFieldsArray[index];
            // build new object with fresh keys to keep it in sync
            var fieldListForLoop = formListLocalRemoteFieldsArray;
            formListLocalRemoteFieldsArray = {};
            var i = 0;
            angular.forEach(fieldListForLoop, function(value) {
                formListLocalRemoteFieldsArray[i] = value;
                i++;
            });
        }


        return formListLocalRemoteFieldsArray;

    }


    /**
     * Method responsible for building the form list with latest data visible on the screen
     * @param formListLatestDataSlugsArray
     * @param action
     * @param fieldSlug
     * @param index
     * @returns {*}
     */

    factory.addOrRemoveLatestDataFormListSlugs = function (formListLatestDataSlugsArray, action, fieldSlug, index = null) {

        if((fieldSlug == '' || typeof fieldSlug == 'undefined') && action == 'add') {
            alert('Please type a field Slug');
            return formListLatestDataSlugsArray;
        }


        if(typeof formListLatestDataSlugsArray == 'undefined') {
            formListLatestDataSlugsArray = {};
        }

        if(action == 'add') {
            formListLatestDataSlugsArray[Object.keys(formListLatestDataSlugsArray).length] = fieldSlug;

        } else {
            console.log(formListLatestDataSlugsArray);
            console.log(index);
            delete formListLatestDataSlugsArray[index];
            // build new object with fresh keys to keep it in sync
            var fieldListForLoop = formListLatestDataSlugsArray;
            formListLatestDataSlugsArray = {};
            var i = 0;
            angular.forEach(fieldListForLoop, function(value) {
                formListLatestDataSlugsArray[i] = value;
                i++;
            });
        }


        return formListLatestDataSlugsArray;

    }

    /**
     * Method responsible for building the form list with latest data visible on the screen
     * @param formListLatestDataSlugFiltersArray
     * @param action
     * @param localField
     * @param remoteField
     * @param index
     * @returns {*}
     */

    factory.addOrRemoveLatestDataFormListSlugFilters = function (formListLatestDataSlugFiltersArray, action, fieldSlug, index = null) {

        if((fieldSlug == '' || typeof fieldSlug == 'undefined') && action == 'add') {
            alert('Please type a field Slug');
            return formListLatestDataSlugFiltersArray;
        }

        if(typeof formListLatestDataSlugFiltersArray == 'undefined') {
            formListLatestDataSlugFiltersArray = {};
        }

        if(action == 'add') {
            formListLatestDataSlugFiltersArray[Object.keys(formListLatestDataSlugFiltersArray).length] = fieldSlug;

        } else {
            delete formListLatestDataSlugFiltersArray[index];
            // build new object with fresh keys to keep it in sync
            var fieldListForLoop = formListLatestDataSlugFiltersArray;
            formListLatestDataSlugFiltersArray = {};
            var i = 0;
            angular.forEach(fieldListForLoop, function(value) {
                formListLatestDataSlugFiltersArray[i] = value;
                i++;
            });
        }


        return formListLatestDataSlugFiltersArray;

    }

    /**
     * Method responsible for setting the field parameters from edit
     * @param fieldParameters
     * @returns {*}
     */

    factory.setInitialFieldParametersFromEdit = function (fieldParameters) {

        var parametersToReturn = {};
        var parametersAvailable = [
            'formceptionTwoStationAndJob',
            'formListLocalRemoteFields',
            'formListLocalRemoteFieldsArray'
        ]
        angular.forEach(fieldParameters, function(fieldParam) {
            if(parametersAvailable.includes(fieldParam.scan_station_field_parameter)) {
                try {
                    parametersToReturn[fieldParam.scan_station_field_parameter] = JSON.parse(fieldParam.parameter_value);
                } catch(e) {
                    parametersToReturn[fieldParam.scan_station_field_parameter] = fieldParam.parameter_value;
                }
            }
        });

        return parametersToReturn;
    }

    return factory;
}

