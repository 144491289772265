import * as angular from 'angular';
import SubBlocController from "./controllers/subbloc.controller";
import StandardComponent from "components/standards/components/StandardComponent";

"use strict";

class SubBlocComponent extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/field/components/subBloc';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        action: '@',
        scanStation: '=',
        fc: '=',
        field: '=',
        row: '=',
        data: '='
    }

    // This will set what will be the controller of this component
    public controller : any = SubBlocController;

    // This will set an alias
    public controllerAs: string = 'subbloc';
}

angular.module('FieldModule').component('subbloc', <StandardComponent> new SubBlocComponent());