import * as angular from 'angular';
import ModuleController from "./controllers/module.controller";

"use strict";

let JobComponent =  {
    templateUrl: 'components/module/template/module.html',
    controller: ModuleController,
    controllerAs: 'mod',
    bindings: {
        data: '<',
        main: '<',
    }
}

angular.module('ModuleModule')
       .component('module', JobComponent);
