import * as angular from 'angular';

"use strict";

angular.module('ManagementModule')
       .factory('ManagementFactory', ManagementFactory);

ManagementFactory.$inject = ['$window'];

/**
 *
 * @constructor
 */
function ManagementFactory($window) {

    let factory = this;

    return factory;
}

export default ManagementFactory;