import * as angular from 'angular';
import * as $ from "jquery";

"use strict";

angular.module('ScanStationModule')
       .service('ScanStationTinyMceService', ScanStationTinyMceService);

ScanStationTinyMceService.$inject = [];
/**
 * This will be responsible to implement filters on the
 * application.
 *
 * @constructor
 */
function ScanStationTinyMceService() {

    let service = this;
    service.model = 'Initial content';
    service.tinymceOptions = {
        invalid_elements: "input,textarea,select,option",
        paste_data_images: true,
        plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap emoticons textcolor',
        imagetools_cors_hosts: ['picsum.photos'],
        menubar: 'file edit view insert format tools table help',
        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
        toolbar_sticky: true,
        autosave_ask_before_unload: true,
        autosave_interval: "30s",
        autosave_prefix: "{path}{query}-{id}-",
        autosave_restore_when_empty: false,
        autosave_retention: "2m",
        image_advtab: true,
        content_css: [
            '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
            '//www.tiny.cloud/css/codepen.min.css',
            '/css/externals.css'
        ],
        link_list: [
            {title: 'My page 1', value: 'http://www.tinymce.com'},
            {title: 'My page 2', value: 'http://www.moxiecode.com'}
        ],
        image_list: [
            {title: 'My page 1', value: 'http://www.tinymce.com'},
            {title: 'My page 2', value: 'http://www.moxiecode.com'}
        ],
        image_class_list: [
            {title: 'None', value: ''},
            {title: 'Some class', value: 'class-name'}
        ],
        importcss_append: true,
        file_picker_callback: function (callback, value, meta) {
            /* Provide image and alt text for the image dialog */
            if (meta.filetype === 'image') {
                callback('https://www.google.com/logos/google.jpg',
                    {alt: 'My alt text'});
            }

            /* Provide alternative source and posted for the media dialog */
            if (meta.filetype === 'media') {
                callback('movie.mp4',
                    {
                        source2: 'alt.ogg',
                        poster: 'https://www.google.com/logos/google.jpg'
                    });
            }

            /* Provide file and text for the link dialog */
            if (meta.filetype === 'file') {
                callback('https://www.google.com/logos/google.jpg',
                    {text: 'My text'});
            }
        },
        templates:[
            {
                title: 'New Table',
                description: 'creates a new table',
                content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>'
            },
            {
                title: 'Starting my story',
                description: 'A cure for writers block',
                content: 'Once upon a time...'
            },
            {
                title: 'New list with dates',
                description: 'New List with dates',
                content: '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>'
            }
        ],
        template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
        template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
        height: '67vh',
        image_caption: true,
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_drawer: 'sliding',
        contextmenu: "link image imagetools table",
        init_instance_callback: function(editor) {
            editor.on('ExecCommand', function(e) {
                console.log('The ' + e.command + ' command was fired.');
            });
        }
    };

    service.tinymceOptionsLight = {
        content_style: ".mce-content-body p { margin: 6px 0px; }",
        menubar: false,
        invalid_elements: "input,textarea,select,option",
        paste_data_images: false,
        toolbar: 'bold italic underline strikethrough  | alignleft aligncenter alignright alignjustify |  numlist bullist',

        content_css: [
            '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
            '//www.tiny.cloud/css/codepen.min.css',
            '/css/custom-tiny-mce.css'
        ],

        importcss_append: true,
        height: 190,
        image_caption: false
    };

    service.init = function () {

        return service;
    }

    service.setTinyMCETemplate = () => {

        // setting to bright template
        if($('#blocworx').hasClass('skin-setting-bright')){
            service.tinymceOptions.body_class = 'skin-setting-bright';
        }

        // setting to dark template
        if($('#blocworx').hasClass('skin-setting-dark')) {
            service.tinymceOptions.body_class = 'skin-setting-dark';
        }
    }

}
