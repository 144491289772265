import * as angular from 'angular';
import 'echarts'
import {AllDataInterface} from "../../../legacy/controllers/AllData.interface";

"use strict";
angular.module('FieldModule')
       .controller('EChartController', EChartController);

// Injection of each class
EChartController.$inject = ['EChartField', 'Configuration', '$scope', 'EChartFactory'];

// Controller Constructor
function EChartController(EChartField, Configuration, $scope, EChartFactory) {

    let vm = this;

    vm.buildEChart = async function(fieldSlug, fieldId, chartOption, scanStationDataToAdd, scanStationFields, customEChartOptions) {

        // TODO make the echart a component and bind scanStationCtrl so we can remove this function

        let allFieldsData = await EChartFactory.getAllFieldsData(scanStationDataToAdd, scanStationFields);

        await EChartField.buildEChart(fieldSlug, fieldId, allFieldsData, chartOption, customEChartOptions);
    }

    vm.$onInit = async function () {
        try
        {
            vm.version = Configuration.getVersion();
            vm.field = EChartField;

        } catch (e) {
            console.log(e);
        }
    }

}
