import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";
import MatchingDataController from "./controllers/matchingData.controller";

"use strict";

class MatchingData extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/field/components/matchingData';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        scanStation: '<',
        fc: '<',
        row: '<',
        processField : '<',
    }


    // This will set what will be the controller of this component
    public controller : any = MatchingDataController;

    // This will set an alias
    public controllerAs: string = 'md';
}

angular.module('FieldModule').component('matchingData', <StandardComponent> new MatchingData);