import * as angular from 'angular';

"use strict";

angular.module('ModuleModule')
       .controller('ModuleController', ModuleController);

ModuleController.$inject = ['$scope'];

// Controller Constructor
function ModuleController($scope)
{
  // Creating the view model reference
  let vm = this;
  vm.data = '';
  vm.main = '';

  vm.$onInit = function () {
    try
    {
      $scope.data = vm.data;
      $scope.main = vm.main;

    } catch (e) {
      console.log(e);
    }

  }

  vm.showIconsBlock = (data) => {
      data.showIconBox=true;
      data.editingBlocIcon=true;
      data.editingModuleIcon=false;
  }

}

export default ModuleController;