import * as angular from 'angular';

"use strict";

angular.module('PreventTemplateCacheModule')
       .factory('preventTemplateCache', PreventTemplateCacheService)
       .config(function ($provide, $httpProvider) {
           $httpProvider.interceptors.push('preventTemplateCache');
       });

PreventTemplateCacheService.$inject = [];
/**
 * This will be responsible to add random numbers for html files while loading
 * them, avoiding the cache on html files.
 *
 * application.
 *
 * @constructor
 */
function PreventTemplateCacheService() {
    let factory = this;

    factory.getMainUrl = (url) => {

        // get query string from url (optional) or window
        let urlSplit = url.split('?');

        if (urlSplit.length <= 0) {
            return false;
        }

        return urlSplit[0];
    }

    factory.getParameters = (url) => {

        // get query string from url (optional) or window
        let queryString = url ? url.split('?')[1] : window.location.search.slice(1);

        // we'll store the parameters here
        let obj = {};

        // if query string exists
        if (queryString) {

            // stuff after # is not part of query string, so get rid of it
            queryString = queryString.split('#')[0];

            // split our query string into its component parts
            let arr = queryString.split('&');

            for (let i = 0; i < arr.length; i++) {
                // separate the keys and the values
                let a = arr[i].split('=');

                // set parameter name and value (use 'true' if empty)
                let paramName = a[0];
                let paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

                // (optional) keep case consistent
                paramName = paramName.toLowerCase();
                if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

                // if the paramName ends with square brackets, e.g. colors[] or colors[2]
                if (paramName.match(/\[(\d+)?\]$/)) {

                    // create key if it doesn't exist
                    let key = paramName.replace(/\[(\d+)?\]/, '');
                    if (!obj[key]) obj[key] = [];

                    // if it's an indexed array e.g. colors[2]
                    if (paramName.match(/\[\d+\]$/)) {
                        // get the index value and add the entry at the appropriate position
                        let index = /\[(\d+)\]/.exec(paramName)[1];
                        obj[key][index] = paramValue;
                    } else {
                        // otherwise add the value to the end of the array
                        obj[key].push(paramValue);
                    }
                } else {
                    // we're dealing with a string
                    if (!obj[paramName]) {
                        // if it doesn't exist, create property
                        obj[paramName] = paramValue;
                    } else if (obj[paramName] && typeof obj[paramName] === 'string') {
                        // if property does exist and it's a string, convert it to an array
                        obj[paramName] = [obj[paramName]];
                        obj[paramName].push(paramValue);
                    } else {
                        // otherwise add the property
                        obj[paramName].push(paramValue);
                    }
                }
            }
        }

        return obj;
    };

    return {
        // On request success
        request: function (config) {

            let params = factory.getParameters(config.url);
            let mainUrl = factory.getMainUrl(config.url);

            if (mainUrl) {

                // Adding the template folder for the legacy things
                if (config.url.indexOf('views') !== -1 || config.url.indexOf('template') !== -1) {

                    params.random = 0;

                    // Checking if exists parameters
                    if (Object.keys(params).length > 0) {

                        // removing the legacy nd parameter
                        if (params.nd !== undefined) {
                            delete params.nd
                        }

                        // removing the legacy v parameter
                        if (params.v !== undefined) {
                            delete params.v
                        }
                    }

                    // creating the mandatory unique param called random
                    if (params.random === 0) {
                        params.random = Math.floor(Math.random() * 1000000000000);
                    }

                    // Mounting the url
                    let mountedUrl = mainUrl + '?' + Object.entries(params)
                        .map(([key, val]) => `${key}=${val}`).join('&');

                    config.url = mountedUrl;
                }

            }

            return config;
        }
    };

}
