import * as angular from 'angular';

"use strict";

angular.module('ScanStationModule')
       .service('resultsField', ResultsField);

/**
 * Service constructor.
 * @constructor
 */
function ResultsField() {
    let service = this;

}