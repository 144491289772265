import * as angular from 'angular';

"use strict";

angular.module('TemplateModule')
       .controller('templateController', TemplateController);

// Injection of each class
TemplateController.$inject = ['$scope', 'TemplateFactory', 'TemplateService', 'DownloadFactory','DownloadService'];

// Controller Constructor
function TemplateController($scope, TemplateFactory, TemplateService, DownloadFactory, DownloadService) {

    // Creating the view model reference
    let vm = this;
    vm.templateFileList = null;
    vm.templateFactory = null;
    vm.scanStation = null;
    vm.templateService = null;

    vm.downloadUrl = null;
    vm.file = null;
    vm.templateName = null;

    vm.$onInit = async function () {
        try {

            $scope.scanStation = vm.scanStation;
            vm.templateFactory = TemplateFactory;
            vm.templateService = TemplateService;
            vm.downloadFactory = DownloadFactory;
            vm.downloadService = DownloadService;

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This will load all scan station templates.
     *
     * @param scanStationId
     */
    vm.getScanStationTemplates = async (scanStationId) => {
        try {

            if (vm.templateFileList === null){
                vm.templateFileList = await vm.templateService.loadScanStationTemplates(scanStationId);
            }

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This will return the template mock file without being populated by the
     * scan station data, this is mainly to check if the current data
     * is ok and the template is ok to be updated later on.
     *
     * @param scanStationId
     * @param chosenTemplateFile
     */
    vm.templateDownloadMock = async (scanStationId, chosenTemplateFile) => {
        try {

            let response = await vm.downloadService.templateDownloadMock(scanStationId, chosenTemplateFile);

            let blob = new Blob([response.data], {
                type: 'application/octet-stream'
            });

            let URL = window.URL || window.webkitURL;
            vm.downloadFactory.downloadUrl = URL.createObjectURL(blob);
            vm.downloadFactory.filename = chosenTemplateFile;

            let element = await document.getElementById('downloadPdfBtn');

            $scope.$apply();
            element.click();

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This will export the mock up of the scan sation variables
     * into a new file.
     *
     * @param scanStationId
     */
    vm.exportMockup = async (scanStationId) => {
        
        let response = await vm.templateService.exportScanStationMockup(scanStationId, 'docx');
        let blob = new Blob([response.data], {
            type: 'application/octet-stream'
        });

        let URL = window.URL || window.webkitURL;
        vm.downloadFactory.downloadUrl = URL.createObjectURL(blob);
        vm.downloadFactory.filename = vm.scanStation.stationName + 'download.docx';
        let element = await document.getElementById('downloadPdfBtn');
        $scope.$apply();
        element.click();

    }

    vm.uploadTemplate = async (scanStationId) => {
        try {
            let templateName = vm.templateName;
            let file = vm.file;
            let response = await vm.templateService.uploadTemplate(scanStationId, templateName, file);
            vm.templateFileList = null;
            vm.templateFileList = await vm.templateService.loadScanStationTemplates(scanStationId);
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This will be responsible to delete a template file and
     * the instance on scan_station_template.
     *
     * @param templateId
     */
    vm.deleteTemplate = async (templateId, scanStationId) => {
        try {

            await vm.templateService.deleteTemplate(templateId);
            vm.templateFileList = null;
            vm.templateFileList = await vm.templateService.loadScanStationTemplates(scanStationId);
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }
    }


}

export default TemplateController;
