import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";
import TableOrderController from "components/scanstation/components/tableOrder/controllers/tableOrder.controller";

"use strict";

class TableOrder extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/scanstation/components/tableOrder';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        scanStation: '<',
        field: '<',
    }

    // This will set what will be the controller of this component
    public controller : any = TableOrderController;

    // This will set an alias
    public controllerAs: string = 'to';


}

angular.module('ScanStationModule').component('tableOrder', <StandardComponent> new TableOrder);

