import * as angular from 'angular';
import * as $ from "jquery";
import {type} from "os";

"use strict";

angular
    .module('FieldModule')
    .factory('fieldParameterFactory', fieldParameterFactory);


fieldParameterFactory.$inject = ['fieldService'];


/**
 * Object that will create a Field Parameter Factory, all methods of factory
 * will be available from outside.
 */
function fieldParameterFactory(fieldService) {

    let factory = this;

    factory.buildParameterValues = async function (fieldsToExcludeFromGetData, field) {
        if (field.parameters.length > 0) {
            angular.forEach(field.parameters, function (fieldParam) {

                try {
                    field[fieldParam.scan_station_field_parameter] = JSON.parse(fieldParam.parameter_value);
                } catch (e) {
                    field[fieldParam.scan_station_field_parameter] = fieldParam.parameter_value;
                }

                if (field[fieldParam.scan_station_field_parameter] == '1') {
                    fieldsToExcludeFromGetData.push(field.field_slug);
                }

                if (field.field_type == 'multi-button' && fieldParam.scan_station_field_parameter == 'multiButtonList') {
                    field.values = JSON.parse(fieldParam.parameter_value);
                }
            });
        }

        return {'fieldsToExcludeFromGetData': fieldsToExcludeFromGetData, 'field': field};
    }

    // Set the existing field parameters for this field when "Edit Field" is clicked
    factory.setInitialFieldParametersFromEdit = async function (field) {

        let initialFieldParams = {};

        // This  is responsible for populate the initial parameters
        let defaultInitialFieldParams = await fieldService.getInitialParams(field.field_type);

        angular.forEach(defaultInitialFieldParams, function (parameterBody, fieldParameterKey) {
            angular.forEach(field, function (propertyValue, propertyKey) {
                if(propertyKey == fieldParameterKey) {
                    initialFieldParams[fieldParameterKey] = field[propertyKey];
                }
            });
        });

        return initialFieldParams;

    }


    return factory;
}

