"use strict";

import * as angular from "angular";

angular.module('ManagementModule').config(homeRoutes);

homeRoutes.$inject = ['ConfigurationProvider', '$stateProvider'];

function homeRoutes(ConfigurationProvider, $stateProvider)
{
    $stateProvider.state('manage-blocworx-changes', {
        parent: 'app',
        url: '/manage-blocworx-changes',
        views: {
            'parent': {
                template: '<management-blocworx action="manage-blocworx-changes" bootstrap="bootstrap"></management-blocworx>'
            }
        },
        params: {
            sidebarHighlighter: 'home'
        }
    }).state('manageBlocworxCustomer', {
        parent: 'app',
        url: '/manage-blocworx-customer/:id',
        views: {
            'parent': {
                template: '<management-blocworx action="manage-blocworx-customer" bootstrap="bootstrap"></management-blocworx>'
            }
        },
        params: {
            sidebarHighlighter: 'home'
        }
    });

}