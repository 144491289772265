import * as angular from 'angular';
import ForgotPasswordController from "./controllers/forgot-password.controller";

"use strict";


let forgotPassword = {
    templateUrl: 'components/reset-forgot-password/template/forgot-password.html',
    controller: ForgotPasswordController,
    controllerAs: 'fp',
    bindings: {
        main: '<',
    }
}

let resetPassword = {
    templateUrl: 'components/reset-forgot-password/template/reset-password.html',
    controller: ForgotPasswordController,
    controllerAs: 'fp',
    bindings: {
        main: '<',
    }
}

angular.module('ResetForgotPasswordModule')
    .component('forgotPassword', forgotPassword)
    .component('resetPassword', resetPassword);