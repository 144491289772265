import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
       .controller('WysiwygController', QrController);

// Injection of each class
QrController.$inject = ['$scope', 'ScopeAndLocal'];

// Controller Constructor
function QrController($scope, ScopeAndLocal) {

    let vm = this;

    vm.$onInit = async function () {
        try
        {
            ScopeAndLocal.startVariable(vm, $scope, 'main');
            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            ScopeAndLocal.startVariable(vm, $scope, 'data');
            ScopeAndLocal.startVariable(vm, $scope, 'field');
            ScopeAndLocal.startVariable(vm, $scope, 'row');
            ScopeAndLocal.startVariable(vm, $scope, 'fc');
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This will setup the htmlContent on the scanStation TinyMceModel object.
     * @param content
     */
    vm.setTinyMCEContent = async (content) => {
        try
        {
            let initialFieldParams = {
                'htmlContent': content
            };
            vm.scanStation.tinymceModel = content;

            if (vm.scanStation.tinyMCEEditingSource === 'new') {
                await vm.scanStation.addScanStationField(initialFieldParams);
            } else {
                vm.scanStation.tempHideFieldEditor = false;
                await vm.scanStation.updateField(initialFieldParams);
            }

        } catch (e) {
            console.log(e);
        } finally {
            vm.scanStation.showTinyMCEEditorPopUp = false;
            $scope.$apply();
        }
    }

}

export default QrController;