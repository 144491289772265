import * as angular from 'angular';
import * as $ from "jquery";
import {type} from "os";

"use strict";

angular
    .module('FieldModule')
    .factory('FieldFactory', FieldFactory);


FieldFactory.$inject = [];


/**
 * Object that will create a Field Factory, all methods of factory
 * will be available from outside.
 */
function FieldFactory() {

    let factory = this;

    factory.showFieldCase = async function(fieldsCategoryToCheck, currentFieldType) {
        if(fieldsCategoryToCheck == 'input-fields') {
            let fieldCategories = [
                'checkbox', 'button', 'multi-button', 'text', 'numeric', 'textarea'
            ];
            if(fieldCategories.includes(currentFieldType)) {
                return true;
            } else {
                return false
            }
        } else {
            return false;
        }
    }



    return factory;
}

