import * as angular from 'angular';

"use strict";

angular
    .module('ManageInactivityModule')
    .factory('ManageInactivityFactory', ManageInactivityFactory)
    .config(ManageInactivityConfiguration);


/**
 * Object that will set aHrefSanitizationWhitelist for requests
 * in this factory.
 *
 * @param $compileProvider
 * @constructor
 */
function ManageInactivityConfiguration($compileProvider: any) {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|file|blob):/);
}

ManageInactivityFactory.$inject = ['$http', 'Configuration'];

function ManageInactivityFactory($http, Configuration) {

    let factory = this

    /**
     * 
     * @param inactivity 
     * @returns {*}
     */
    factory.setInactivity = function (inactivity: any) {
        let apiBase = Configuration.getApiUrl() + 'set-inactivity-timeout';
        let body = {
            settingKey: 'inactivity_timeout',
            settingValue: inactivity
        }
        return $http.post(apiBase, body);
    }

    factory.updateInactivitySettingFromLocalStorage = function (timeout: any) {

        let appSettingParsed = JSON.parse(localStorage.getItem('appSettings'));

        angular.forEach(appSettingParsed.data, function (settingEntry) {
            if (settingEntry.setting_key == 'inactivity_timeout') {
                settingEntry.setting_value = timeout;
            }
        })

        localStorage.setItem('appSettings', JSON.stringify(appSettingParsed))

    }

    return factory
}