import * as angular from 'angular';
import Echo from "laravel-echo"
declare const window: any;
import Pusher from "pusher-js";

"use strict";

angular.module('SocketsModule')
    .controller('GetLatestDataController', GetLatestDataController);

// Injection of each class
GetLatestDataController.$inject = ['$scope', 'ScopeAndLocal', '$state', 'Configuration'];

// Controller Constructor
function GetLatestDataController($scope, ScopeAndLocal, $state, Configuration) {

    let vm = this;

    vm.$onInit = async function () {
        try {
            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            ScopeAndLocal.startVariable(vm, $scope, 'data');
            ScopeAndLocal.startVariable(vm, $scope, 'main');

            vm.runStream();

        } catch (e) {
            console.log(e);
        }
    }

    vm.runStream = async function() {
        window.pusher = new Pusher(Configuration.getPusherAppId(), {
            cluster: 'mt1',
            forceTLS: true,
            enabledTransports: ['ws', 'wss'],
            wssPort: Configuration.getWSPort(),
            wsPort: Configuration.getWSPort(),
            wsHost: window.location.hostname
        });
        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: Configuration.getPusherAppKey(),
            cluster: 'mt1',
            wsHost: window.location.hostname,
            wssPort: Configuration.getWSPort(),
            wsPort: Configuration.getWSPort(),
            forceTLS: true,
            enabledTransports: ['ws', 'wss'],
            encrypted: true,
            disableStats: true
        });

        window.Echo.channel('getLatestDataChannel_' + vm.scanStation.scanStationObj.id).listen('GetLatestDataEvent', async function (e) {
           if(e.data != null && e.data == 'updateData') {
               if (vm.scanStation.dataFilteringData.filterDataToQuery != null && Object.keys(vm.scanStation.dataFilteringData.filterDataToQuery).length > 0) {
                   await vm.scanStation.getDataForDataFiltering('with-filter', vm.scanStation.filterDataOptions);
               } else {
                   await vm.scanStation.getData(vm.scanStation.scanStationObj.id);
               }

               $scope.$apply();
           }
        });
    }

}


export default GetLatestDataController;