import * as angular from 'angular';
import * as moment  from "moment";

"use strict";

angular.module('MomentModule')
       .filter('moment', MomentService);

function MomentService(Configuration, $rootScope) {
    return function(date, dateFormat) {
        let formattedDate = moment(date).format(dateFormat);
        return formattedDate;
    };
}