import * as angular from 'angular';

"use strict";

angular.module('ScanStationModule')
       .controller('ResultsController', ResultsController);

// Injection of each class
ResultsController.$inject = ['$scope', 'ScopeAndLocal', 'FileBuilderFactory'];

// Controller Constructor
function ResultsController($scope, ScopeAndLocal, FileBuilderFactory) {

    let vm = this;

    vm.$onInit = async function () {
        try {
            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            ScopeAndLocal.startVariable(vm, $scope, 'main');
            ScopeAndLocal.startVariable(vm, $scope, 'data');
        } catch (e) {
            console.log(e);
        }
    }
}


export default ResultsController;