import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";
import SourceController from "./controllers/source.controller";

"use strict";

class SourceBloc extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/field/components/sourceBloc';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        scanStation: '<',
        fc: '<',
        row: '<',
        processField : '<',
    }


    // This will set what will be the controller of this component
    public controller : any = SourceController;

    // This will set an alias
    public controllerAs: string = 'sc';
}

angular.module('FieldModule').component('sourceBloc', <StandardComponent> new SourceBloc);