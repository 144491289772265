import * as angular from 'angular';
import PermissionScanStationController from "../controllers/permission.scanStation.controller";
import jQuery from "jquery";

"use strict";

angular.module('PermissionModule')
       .directive('editDataLink', showMoreDataDirective);

/**
 * Directive that will build the show more.
 *
 * @returns
 * @constructor
 */
function showMoreDataDirective() {

    let directive = this;

    return ({
        templateUrl: 'components/permission/template/edit-data-link.html',
        controller: PermissionScanStationController,
        controllerAs: 'psc',
        restrict: 'A',
        scope: {
            scanStation: '<',
            main: '<',
            data: '<',
            element: '<',
            row: '<',
            rowKey: '<',
        },
        link: function (scope, element, attributes) {

            let elementClass = "." + attributes.class;
            let aElement = jQuery(element).find('a');

            // If someone clicks to the td, it will be clicking to the
            // a element inside of this directive
            element.bind('click', function (event) {
                if(aElement.length > 0){
                    jQuery(aElement)[0].click()
                }
            });
        }
    });
}