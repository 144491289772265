import * as angular from 'angular';
import jQuery from "jquery";
import StatusService from "components/blocworx/services/status.service";

declare const window: any;

"use strict";

angular.module('FieldModule')
    .controller('MindeeController', MindeeController);

// Injection of each class
MindeeController.$inject = ['$rootScope', '$scope', 'ScopeAndLocal', 'sound', 'status', 'Blocworx', 'MindeeFactory', '$window'];

// Controller Constructor
function MindeeController($rootScope, $scope, ScopeAndLocal, sound, status, Blocworx, MindeeFactory, $window) {

    let vm = this;

    vm.tokenRef = localStorage.getItem('satellizer_token');

    vm.$onInit = async function () {
        try {
            ScopeAndLocal.startVariable(vm, $scope, 'action');
            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            ScopeAndLocal.startVariable(vm, $scope, 'data');
            ScopeAndLocal.startVariable(vm, $scope, 'fc');
            ScopeAndLocal.startVariable(vm, $scope, 'field');
            ScopeAndLocal.startVariable(vm, $scope, 'row');
            status.runAfterSuccess(vm.runAfterSuccess, vm.scanStation);
            vm.showBulkUpload = [];

        } catch (e) {
            console.log(e);
        }
    }


    /**
     * TODO Raf : I needed to do my own one because runAfterSuccess didnt work on second submit
     */

    $rootScope.$on('cleanMindeeFiles', async function (event, value) {
        await vm.runAfterSuccess();
        $scope.$apply()
    });

    vm.runAfterSuccess = async () => {
        vm.files = {};
        angular.forEach(vm.scanStation.scanStationObj.scanStationFields, function(field) {
           if(field.field_type == 'mindee') {
               (<HTMLInputElement>document.getElementById('station-field-' + field.fieldIndex)).value = '';
               vm.scanStation.scanStationObj.filesToAdd[field.field_slug] = {};
               vm.scanStation.filesToAdd[field.field_slug] = {};
           }

        });
    }

    /**
     * This will be checkig if we are in a bulk case or not. It uses another fields parameters to determine if it is, if there are no parameters
     * its a single case
     */


    vm.isBulkCase = async (field) => {

        if(field.externalButtonToChooseBulkOrNot != null && vm.scanStation.scanStationFieldIdsToField[field.externalButtonToChooseBulkOrNot] != null) {
            let fieldThatChoosesCase = vm.scanStation.scanStationFieldIdsToField[field.externalButtonToChooseBulkOrNot];
            let dataValueOfCaseField = vm.scanStation.scanStationObj.dataToAdd[fieldThatChoosesCase.field_slug];

            if(field.externalButtonValueForBulk == dataValueOfCaseField) {
                return true;
            }

            if(field.externalButtonValueForSingle == dataValueOfCaseField) {
                return false;
            }
        } else {

            return false;
        }

    }

    /**
     * This is a watcher to update the bulk cases on the form based on values in the data changing.
     */


    $window.addEventListener('message', async function (data) {
        if(data.data != null && data.data == 'updateBulkCases') {
            angular.forEach(vm.scanStation.scanStationObj.scanStationFields, async function (field, index) {
                 if (field.field_type == 'mindee') {
                        vm.showBulkUpload[field.id] = await vm.isBulkCase(field);
                        $scope.$apply();
                    }
            });
        }
    });



    /**
     * This will be the action of change to a bulk case.
     */
    vm.setBulkCase = () => {
        vm.showBulkUpload[vm.field.id] = true;
    }

    /**
     * This will be checkig if we are in a bulk case or not.
     */
    vm.isSingleCase = () => {
        return vm.showBulkUpload[vm.field.id] == false;
    }

    /**
     * This will be the action of change to a bulk case.
     */
    vm.setSingleCase = () => {
        vm.showBulkUpload[vm.field.id] = false;
    }

    vm.sendMindeePostRequest = async function (fieldSlug, fieldId, uploadType) {
        try {
            let files = vm.files[fieldSlug];
            vm.pleasewait = true;
            vm.errorMsg = false;

            let allFieldsData = vm.scanStation.getAllFieldsData();
            try {
                let data = await MindeeFactory.sendMindeePostRequest(vm.scanStation.scanStationObj.stationDetails.job_id, vm.scanStation.id, files, fieldId, allFieldsData, uploadType);
                if (uploadType == 'single') {
                    angular.forEach(data.data.responseData, function (response) {
                        vm.scanStation.scanStationObj.dataToAdd[response.slug] = response.value;
                        let field = vm.scanStation.scanStationFieldSlugsToField[response.slug];
                        vm.showImageNow = true;
                        vm.scanStation.getRules('no-event', field.id, field.special_field_key, vm.scanStation.scanStationObj.dataToAdd[field.field_slug], field.fieldIndex, field.field_slug);
                    });
                } else {
                    alert('Your Files have been Uploaded');
                    if (vm.scanStation.dataFilteringData.filterDataToQuery != null && Object.keys(vm.scanStation.dataFilteringData.filterDataToQuery).length>0) {
                        await vm.scanStation.getDataForDataFiltering('with-filter', vm.scanStation.filterDataOptions);
                    } else {
                        await vm.getData(vm.scanStation.id);
                    }
                }

                vm.scanStation.scanStationObj.filesToAdd[fieldSlug] = files;
                vm.pleasewait = false;
            } catch(e) {
                vm.pleasewait = false;
                vm.errorMsg = 'We\'re sorry, there was a problem with this file upload.'
            }



            $scope.$apply();

        } catch (e) {
            console.log(e);
            vm.pleasewait = false;
        }

    };

    vm.canShowPreview = function (fieldSlug) {
        return (vm.scanStation.scanStationObj.dataToAdd[fieldSlug] != null && vm.scanStation.scanStationObj.dataToAdd[fieldSlug] != 'N/A' && vm.scanStation.scanStationObj.dataToAdd[fieldSlug] != '');
    }

    /**
     * Set what is the active field for the controller build of this
     * component.
     * @param fieldName
     */
    vm.activeField = (fieldName) => {
        vm.scanStation.activeField = fieldName;
    }

    /**
     * This will do the check to see if this filed should
     * show or hide.
     * @param fieldType
     */
    vm.showField = (fieldType) => {
        return vm.scanStation.newField.field_type === fieldType;
    }

    vm.mapFieldsToMindeeKeys = async function (action, localFieldId, mindeeKey, index = null) {
        vm.fc.initialFieldParams.mindeeMappedFieldsArray = await MindeeFactory.mapFieldsToMindeeKeys(vm.fc.initialFieldParams.mindeeMappedFieldsArray, action, localFieldId, mindeeKey, index);
        await vm.updateMindeeMappedFieldsFromArray();
        $scope.$apply();
    }

    vm.updateMindeeMappedFieldsFromArray = async function () {
        let mindeeMappedFieldsArray = angular.copy(vm.fc.initialFieldParams.mindeeMappedFieldsArray);
        angular.forEach(mindeeMappedFieldsArray, function (fieldPairMatch) {
            delete fieldPairMatch.localFieldName;
        });

        vm.fc.initialFieldParams.mindeeMappedFields = JSON.stringify(mindeeMappedFieldsArray);

    }


}

export default MindeeController;