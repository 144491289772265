import * as angular from 'angular';
import UsersController from "./controllers/users.controller";
import StandardComponent from "components/standards/components/StandardComponent";

"use strict";

class Users extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/users';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        main: '<',
        data: '<',
    }

    // This will set what will be the controller of this component
    public controller : any = UsersController;

    // This will set an alias
    public controllerAs: string = 'module';
}

angular.module('UsersModule').component('users', <StandardComponent> new Users);