import * as angular from 'angular';
import jQuery from "jquery";
import 'angular-qrcode';

declare const window: any;

"use strict";

angular.module('FieldModule')
    .controller('QrController', QrController);

// Injection of each class
QrController.$inject = ['$rootScope', '$scope', 'ScopeAndLocal', 'sound', 'status', 'Blocworx', 'qrcodeField'];

// Controller Constructor
function QrController($rootScope, $scope, ScopeAndLocal, sound, status, Blocworx, qrcodeField) {

    let vm = this;

    vm.$onInit = async function () {
        try {
            ScopeAndLocal.startVariable(vm, $scope, 'action');
            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            ScopeAndLocal.startVariable(vm, $scope, 'fc');
            ScopeAndLocal.startVariable(vm, $scope, 'field');
            ScopeAndLocal.startVariable(vm, $scope, 'row');

            vm.buttonId = 'btn-scan-qr';
            vm.dataReference = 'qrcode';
            vm.keepData = false;
            vm.scanned = false;

            // starting the frontend item behaviour closed
            vm.qrShow = false;

            // this starts without being scanning the qr code
            vm.scanning = false;

            if (vm.field !== undefined) {
                // starting the id of the button to open the QR code scanner
                vm.buttonId = `btn-scan-qr-${vm.field.field_slug}`;

                // starting the qr code field id variable for dataToAdd
                vm.dataReference = `${vm.field.field_slug}`;
            }

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Method that will start the component.
     */
    vm.startComponent = async (field) => {

        vm.qrcode = window.qrcode;
        vm.video = <HTMLVideoElement>document.createElement("video");
        vm.canvasElement = document.getElementById("qr-canvas");
        vm.canvas = vm.canvasElement.getContext("2d");
        vm.qrcodeButtonText = field.qrcodeButtonText;
        vm.qrcodeSoundAfterScan = field.qrcodeSoundAfterScan;
        status.runAfterSuccess(vm.runAfterSuccess, vm.scanStation);
    }

    /**
     * Method that will run after the scan station controller finish
     * to add data, so this will be cleaning anything related to the
     * QR Code.
     *
     * @param newValue
     * @param oldValue
     * @param scanStation
     */
    vm.runAfterSuccess = (newValue, oldValue, scanStation) => {

        // if the value is false, we must clean the data after the success
        if (!vm.keepData) {
            scanStation.scanStationObj.dataToAdd[vm.dataReference] = undefined;
        }
    }

    /**
     * Method that will be checking if we should keep the data or not.
     */
    vm.shouldWeKeepData = () => {
        if (vm.scanStation === undefined || vm.scanStation.fieldRules === undefined) {
            vm.keepData = false;
        } else {
            angular.forEach(vm.scanStation.fieldRules, function (rule, key) {
                // checked that we have a rule for this field
                if (rule.field_id === vm.field.id) {

                    // if we keep the data, this result box must be shown
                    if (rule.rule_condition_id === 'keep_data_for_next_scan') {
                        vm.keepData = true;
                    }
                }
            });
        }

    }

    /**
     * Set what is the active field for the controller build of this
     * component.
     * @param fieldName
     */
    vm.activeField = (fieldName) => {
        vm.scanStation.activeField = fieldName;
    }

    /**
     * This will do the check to see if this filed should
     * show or hide.
     * @param fieldType
     */
    vm.showField = (fieldType) => {
        return vm.scanStation.newField.field_type === fieldType;
    }

    /**
     * This method will be adding a new QR Code to a scan station
     * object.
     */
    vm.openCameraForQrScan = async (field, $event, fieldRules) => {
        try {

            vm.clicked = true;
            vm.scanStation.fieldRules = fieldRules;
            vm.shouldWeKeepData();
            vm.qrShow = true;
            vm.btnScanQR = document.getElementById(vm.buttonId);
            vm.btnScanQR.hidden = false;
            vm.scanStation.scanStationObj.restarted = false;
            vm.field = field;
            vm.$event = $event;

            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                let stream = await navigator.mediaDevices.getUserMedia({
                    video: {
                        facingMode: 'environment'
                    }
                });
                // this will start the scanning
                vm.scanning = true;
                vm.btnScanQR.hidden = true;
                vm.canvasElement.hidden = false;
                vm.video.setAttribute("playsinline", 'true'); // required to tell iOS safari we don't want fullscreen
                vm.video.srcObject = stream;
                await vm.video.play();
                vm.tick();
                await vm.scan();
            }

        } catch (e) {
            console.log(e);
            vm.clicked = false;

            let issuedElement = jQuery('#btn-scan-qr-qr_code');
            issuedElement.trigger('click');

        }
    }

    /**
     * Method that will build the canvas element.
     */
    vm.tick = () => {
        vm.canvasElement.height = vm.video.videoHeight;
        vm.canvasElement.width = vm.video.videoWidth;
        vm.canvas.drawImage(vm.video, 0, 0, vm.canvasElement.width, vm.canvasElement.height);
        vm.scanning && requestAnimationFrame(vm.tick);
    }

    /**
     * Method that will set the data into the html as well as on
     * dataToAdd object.
     *
     * @param res
     */
    vm.setData = (res) => {

        let filtered = qrcodeField.filterQrCodeHttp(res);
        vm.scanStation.scanStationObj.dataToAdd[vm.dataReference] = filtered;
    }

    /**
     * Method that will be starting the scan process
     * it is on a try catch that will always try again if
     * something #get wrong.
     */
    vm.scan = async () => {
        try {
            vm.processed = false;
            vm.shouldWePlaySound = false;

            let res = await vm.qrcode.decode();
            vm.setData(res);

            vm.video.srcObject.getTracks().forEach(track => {
                track.stop();
            });

            vm.scanning = false;
            vm.qrShow = false;
            vm.btnScanQR.hidden = false;
            vm.processed = true;
            vm.scanned = true;

            // This will be triggering a sound if everything went well with the scan action
            if (vm.shouldWePlaySound = sound.shouldPlaySound(vm.scanStation.scanStationObj, 'qrcodeSoundAfterScan')) {
                await sound.playScanSound();
            }

            $scope.$apply();

        } catch (e) {
            vm.processed = false;
            vm.scanned = false;
            setTimeout(vm.scan, 300);
        } finally {

            if (vm.processed === true) {

                // do scan station validate rules
                await vm.scanStation.getRules(
                    vm.$event,
                    vm.field.id,
                    vm.field.special_field_key,
                    vm.scanStation.scanStationObj.dataToAdd[vm.field.field_slug],
                    vm.field.fieldIndex,
                    vm.field.field_slug
                );

                // run scan station triggerBackgroundDataUpdate
                await vm.scanStation.triggerBackgroundDataUpdate(
                    vm.scanStation.scanStationObj.dataToAdd,
                    vm.scanStation.scanStationObj.id,
                    vm.scanStation.scanStationObj.dataToAdd[vm.field.field_slug]
                );
            }

        }
    }

    /**
     * This is the action of close of a QR Scanner.
     */
    vm.closeQrScanner = () => {

        // closing the tab
        vm.qrShow = false;
        vm.btnScanQR.hidden = false;

    }

    /**
     * This will be checking if we can show or hide the qrcode
     * results block.
     *
     */
    vm.keepShowing = () => {

        let keepShowing = true;

        // If we can find value set, we must be able to show the field data
        if(vm.scanStation.scanStationObj.dataToAdd[vm.field.field_slug] != null){
            return true;
        }

        if(!vm.clicked){
            return false;
        }

        if (!vm.scanned) { // before scan

            // this should hide if it is the opposite status of the main webcam container
            if (vm.qrShow) {
                keepShowing = false;
            }

            // This should hide if the value is undefined
            if (Blocworx.isEmpty(vm.scanStation.scanStationObj.dataToAdd[vm.dataReference])) {
                keepShowing = false;
            }

        } else {
            if (Blocworx.isEmpty(vm.scanStation.scanStationObj.dataToAdd[vm.dataReference])) {
                keepShowing = false;
            }
        }

        return keepShowing;
    }

    /**
     * This method will be checking if the object is empty.
     * @param obj
     */
    vm.isEmpty = (obj) => {

        if (obj === undefined || obj === ''){
            return true;
        }
        return false;
    }

}

export default QrController;