import * as angular from 'angular';
import * as $ from "jquery";

"use strict";

angular
    .module('FieldModule')
    .factory('SubmitButtonFieldFactory', SubmitButtonFieldFactory);


SubmitButtonFieldFactory.$inject = ['fieldService', 'Configuration', '$http'];


/**
 * Object that will create a Plain Data Factory, all methods of factory
 * will be available from outside.
 */
function SubmitButtonFieldFactory(fieldService, Configuration, $http) {
    let factory = this;

    return factory;
}

