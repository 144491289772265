import * as angular from 'angular';

"use strict";

angular.module('ScanStationModule')
       .service('ScanStationService', ScanStationService);

ScanStationService.$inject = [];
/**
 * This will be responsible to implement filters on the
 * application.
 *
 * @constructor
 */
function ScanStationService() {
    let service = this;
    let scanStationFields = null;

    /**
     * Constructor method that is necessary to start this whole object,
     * so as its returning the whole service you will be allowed to do:
     *
     *    ScanStationService.init(scanStationFields).getFieldByFieldId(field_id)
     *
     * @param scanStationFields scan station fields array object with
     * all data.
     *
     * @returns {ScanStationService} returns the instance of this service
     * so you should concatenate with it.
     */
    service.init = function (scanStationFields) {
        service.scanStationFields = scanStationFields;

        return service;
    }

    /**
     * Method that will get a field by the field id, on the
     * scanStationFields that is started using the init().
     *
     * @param field_id id of the field.
     * @returns {boolean} false if can't find, otherwise will
     * return the field object.
     */
    service.getFieldByFieldId = function (field_id) {
        let filterField = false;

        // getting information of the field
        angular.forEach(service.scanStationFields, function (field) {
            if (field.id == field_id) {
                filterField = field;
            }
        });

        return filterField;

    }

}
