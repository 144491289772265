import * as angular from 'angular';

"use strict";

angular.module('SuperUserModule')
       .service('SuperuserService', SuperuserService);

/**
 * Object that will be responsible to store all configuration's
 * data from the whole application
 *
 * @returns {Configuration}
 * @constructor
 */
function SuperuserService() {
    let service = this;

    service.superUser = false;

    // This will return the main service of super user or not.
    service.isSuperUser = function (main) {
        service.superUser = main.auth.userData.super_user == 1;
        return service.superUser;
    }

}
