import * as angular from 'angular';
import * as $ from "jquery";

"use strict";

angular
    .module('FieldModule')
    .factory('DigitalSignatureFieldFactory', DigitalSignatureFieldFactory);


DigitalSignatureFieldFactory.$inject = ['Configuration', '$http'];


/**
 * Object that will create a Digital Signature Data Factory, all methods of factory
 * will be available from outside.
 */
function DigitalSignatureFieldFactory(Configuration, $http) {
    let factory = this;

    /**
     * Method responsible for setting the field parameters from edit
     * @param fieldParameters
     * @returns {*}
     */

    factory.setInitialFieldParametersFromEdit = function (fieldParameters) {

        var parametersToReturn = '';
        angular.forEach(fieldParameters, function(fieldParam) {

        });

        return parametersToReturn;
    }

    /**
     * This is to authenticate a user and get back to the decided value
     * @param fieldId
     * @param allFieldsData
     * @returns {*}
     */
    factory.authenticateUserInForm = async function (fieldId, inFormUserAuthUsername, inFormUserAuthPassword) {

        let apiBase = Configuration.getApiUrl() + 'authenticate-user-in-form';
        let data = {
            fieldId: fieldId,
            inFormUserAuthUsername: inFormUserAuthUsername,
            inFormUserAuthPassword: inFormUserAuthPassword
        };
        return $http.post(apiBase, data);
    };

    return factory;
}

