import * as angular from 'angular';
import * as $ from "jquery";

"use strict";

angular
    .module('FieldModule')
    .factory('MindeeFactory', MindeeFactory);


MindeeFactory.$inject = ['fieldService', 'Configuration', '$http'];


/**
 * Object that will create a Plain Data Factory, all methods of factory
 * will be available from outside.
 */
function MindeeFactory(fieldService, Configuration, $http) {
    let factory = this;

    factory.sendMindeePostRequest = function (jobID, stationID, files, fieldId, allFieldsData, uploadType) {

        let fd = new FormData();
        fd.append('jobID', jobID);
        fd.append('stationID', stationID);
        fd.append('allFieldsData', JSON.stringify(allFieldsData));
        fd.append('uploadType', uploadType);

        if(uploadType == 'single') {
            fd.append('file_0', files);
        } else {
            let i = 0;
            angular.forEach(files, function (value) {
                fd.append('file_' + i, value);
                i++;
            });
        }

        fd.append('fieldId' , fieldId);

        let apiBase = Configuration.getApiUrl() + 'send-mindee-post-request';
        return $http.post(apiBase, fd, {
            transformRequest: angular.identity,
            headers: {'Content-Type': undefined}
        });

    }

    /**
     * Method responsible for matching local fields with the keys from the Mindee API
     * @param localFieldMatchingFieldPairsArray
     * @param action
     * @param localField
     * @param remoteField
     * @param index
     * @returns {*}
     */
    factory.mapFieldsToMindeeKeys = function (mindeeMappedFieldsArray, action, localField, mindeeKey, index = null) {

        if((localField == '' || typeof localField == 'undefined') && action == 'add') {
            alert('Please Select a local Field');
            return mindeeMappedFieldsArray;
        }

        if((mindeeKey == '' || typeof mindeeKey == 'undefined') && action == 'add') {
            alert('Please Select a mindee key');
            return mindeeMappedFieldsArray;
        }

        if(typeof mindeeMappedFieldsArray == 'undefined') {
            mindeeMappedFieldsArray = {};
        }

        if(action == 'add') {
            localField = JSON.parse(localField);
            mindeeMappedFieldsArray[Object.keys(mindeeMappedFieldsArray).length] = {
                'localFieldId' : localField.id,
                'mindee_key_name' : mindeeKey,
                'localFieldName' : localField.field_name
            };

        } else {
            delete mindeeMappedFieldsArray[index];
            // build new object with fresh keys to keep it in sync
            var fieldListForLoop = mindeeMappedFieldsArray;
            mindeeMappedFieldsArray = {};
            var i = 0;
            angular.forEach(fieldListForLoop, function(value) {
                mindeeMappedFieldsArray[i] = value;
                i++;
            });
        }



        return mindeeMappedFieldsArray;

    }

    factory.updateBulkCases =  function() {
        window.postMessage('updateBulkCases', '*');
    }

    return factory;
}

