import * as angular from 'angular';

"use strict";

angular.module('ResetForgotPasswordModule')
    .factory('ForgotPasswordFactory', ForgotPasswordFactory)
    .config(ForgotPasswordConfiguration);

/**
 * Object that will set aHrefSanitizationWhitelist for requests
 * in this factory.
 *
 * @param $compileProvider
 * @constructor
 */
function ForgotPasswordConfiguration($compileProvider) {
    $compileProvider.aHrefSanitizationWhitelist(
        /^\s*(https?|ftp|mailto|tel|file|blob):/);
}

ForgotPasswordFactory.$inject = ['$http', 'Configuration'];

/**
 * Object that will create a ForgotPassword Factory, all methods of factory
 * will be available from outside.
 *
 * @param $http
 * @param Configuration
 * @constructor
 */
function ForgotPasswordFactory($http, Configuration) {

    let factory = this;

    /**
     *  method to reset password
     */
    factory.resetPassword = async function(emailAddress, token, password) {
        let apiBase = Configuration.getApiUrl() + 'reset-password';
        let data = {
            emailAddress: emailAddress,
            token: token,
            password: password
        };
        return $http.post(apiBase, data);
    }

    /**
     *  method to send the reset password link
     */


    factory.sendResetPasswordLink = async function(emailAddress) {
        let apiBase = Configuration.getApiUrl() + 'send-reset-password-link';
        let data = {
            emailAddress: emailAddress,
        };
        return $http.post(apiBase, data);
    }



    return factory;

}
