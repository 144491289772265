import * as angular from 'angular';
import PermissionSwitchController from "./controllers/permission.switch.controller";
import PermissionScanStationController from "./controllers/permission.scanStation.controller";

"use strict";

let SwitchComponent =  {
    templateUrl: 'components/permission/template/switch.html',
    controller: PermissionSwitchController,
    controllerAs: 'switch',
    bindings: {
        scanStation: '<',
        main: '<',
        to: '<',
        data: '<',
    }
}


let EditDataComponent =  {
    templateUrl: 'components/permission/template/edit-data.html',
    controller: PermissionScanStationController,
    controllerAs: 'psc',
    bindings: {
        scanStation: '<',
        main: '<',
        data: '<',
        element: '<',
        row: '<',
        rowKey: '<',
    }
}

let SearchDataComponent =  {
    templateUrl: 'components/permission/template/search-data.html',
    controller: PermissionScanStationController,
    controllerAs: 'psc',
    bindings: {
        scanStation: '<',
        main: '<',
        data: '<',
        element: '<',
        row: '<',
        rowKey: '<',
    }
}

let InDataExportButton =  {
    templateUrl: 'components/permission/template/in-data-export-button.html',
    controller: PermissionScanStationController,
    controllerAs: 'psc',
    bindings: {
        scanStation: '<',
        main: '<',
        data: '<',
        element: '<',
        rowId: '<',
        rowKey: '<',
    }
}

angular.module('PermissionModule')
    .component('switch', SwitchComponent)
    .component('editData', EditDataComponent)
    .component('searchData', SearchDataComponent)
    .component('inDataExportButton', InDataExportButton);
