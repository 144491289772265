import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";
import TextfieldController from "./controllers/textfield.controller";

"use strict";

class TextfieldComponent extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/field/components/textfield';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        scanStation: '=',
        field: '=',
    }

    // This will set what will be the controller of this component
    public controller : any = TextfieldController;

    // This will set an alias
    public controllerAs: string = 'tc';
}

angular.module('FieldModule').component('textfield', <StandardComponent> new TextfieldComponent);