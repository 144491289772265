import * as angular from 'angular';
import * as $ from "jquery";

"use strict";

angular
    .module('FieldModule')
    .factory('PieChartFactory', PieChartFactory);


PieChartFactory.$inject = [];


/**
 * Object that will create a Multi Button Factory, all methods of factory
 * will be available from outside.
 */
function PieChartFactory() {
    let factory = this;

    /**
     * Method responsible for building and editing the multi button field
     * @param pieChartFieldList
     * @param action
     * @param fieldId
     * @param index
     * @returns {*}
     */
    factory.addOrRemovePieFromChartList = function (pieChartFieldList, action, fieldId, index = null) {
        if((fieldId == '' || typeof fieldId == 'undefined') && action == 'add') {
            alert('Please select a field');
            return pieChartFieldList;
        }
        if(typeof pieChartFieldList == 'undefined') {
            pieChartFieldList = {};
        }

        if(action == 'add') {

            pieChartFieldList[Object.keys(pieChartFieldList).length] = fieldId;

        } else {
            delete pieChartFieldList[index];
            // build new object with fresh keys to keep it in sync
            var multiButtonListForLoop = pieChartFieldList;
            pieChartFieldList = {};
            var i = 0;
            angular.forEach(multiButtonListForLoop, function(value) {
                pieChartFieldList[i] = value;
                i++;
            });
        }

        return pieChartFieldList;

    }

    /**
     * Method responsible for setting the field parameters from edit
     * @param fieldParameters
     * @returns {*}
     */

    factory.setInitialFieldParametersFromEdit = function (fieldParameters) {

        var parametersToReturn = {};
        angular.forEach(fieldParameters, function(fieldParam) {
            if(fieldParam.scan_station_field_parameter == 'chartFieldList') {
                parametersToReturn = JSON.parse(fieldParam.parameter_value);
            }
        });

        return parametersToReturn;
    }

    return factory;
}

