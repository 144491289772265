import * as angular from 'angular';

"use strict";

angular.module('RolesAndPermissionsModule')
    .factory('RolesAndPermissionsFactory', RolesAndPermissionsFactory)
    .config(RolesAndPermissionsConfiguration);

/**
 * Object that will set aHrefSanitizationWhitelist for requests
 * in this factory.
 *
 * @param $compileProvider
 * @constructor
 */
function RolesAndPermissionsConfiguration($compileProvider) {
    $compileProvider.aHrefSanitizationWhitelist(
        /^\s*(https?|ftp|mailto|tel|file|blob):/);
}

RolesAndPermissionsFactory.$inject = ['$http', 'Configuration'];

/**
 * Object that will create a RolesAndPermissions Factory, all methods of factory
 * will be available from outside.
 *
 * @param $http
 * @param Configuration
 * @constructor
 */
function RolesAndPermissionsFactory($http, Configuration) {

    let factory = this;


    /**
     * Method that will get all permissions set in the api
     */
    factory.getAllPermissions = function () {

        let apiBase = Configuration.getApiUrl() + 'get-all-permissions';
        let data = {};
        return $http.get(apiBase, data);
    }

    /**
     *  method to get the roles created by this customer and the permissions the role has
     */
    factory.getRolesAndPermissions = function () {

        let apiBase = Configuration.getApiUrl() + 'get-roles-and-permissions';
        let data = {};
        return $http.get(apiBase, data);
    }

    /**
     *  method to create a new role with permissions
     */
    factory.createNewRole = async function (newRoleName, newRoleSelectedPermissions, requiresTwoFactorAuthentication) {

        
        let apiBase = Configuration.getApiUrl() + 'create-new-role';
        let data = {
            newRoleName: newRoleName,
            newRoleSelectedPermissions: newRoleSelectedPermissions,
            requiresTwoFactorAuthentication
        };
        return $http.post(apiBase, data);
    }

    /**
     *  method to edit a role with permissions
     */

    factory.updateRole = async function (roleId, editedRoleName, permissions, requires2FA) {


        let apiBase = Configuration.getApiUrl() + 'update-role';
        let data = {
            roleId: roleId,
            editedRoleName: editedRoleName,
            permissions: permissions,
            requires2FA
        };
        return $http.post(apiBase, data);
    }

    /**
     *  method to add roles to stations
     */


    factory.updateStationRoles = async function(roleGroups, stationId) {
        let apiBase = Configuration.getApiUrl() + 'update-station-roles';
        let data = {
            roleGroups: roleGroups,
            stationId: stationId
        };
        return $http.post(apiBase, data);
    }

    /*
        Method to delete the role
        @param roleId
    */

    factory.deleteRole = async function (roleId) {
        let apiBase = Configuration.getApiUrl() + 'delete-role';
        let data = {
            params : {roleId: roleId}
        };

        return $http.delete(apiBase, data);
    }


    return factory;

}
