import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";
import MindeeController from "./controllers/mindee.controller";

"use strict";

class MindeeComponent extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/field/components/mindee';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        action: '@',
        scanStation: '=',
        fc: '=',
        field: '=',
        row: '=',
        editScanStation: '@',
        data: '='
    }

    // This will set what will be the controller of this component
    public controller : any = MindeeController;

    // This will set an alias
    public controllerAs: string = 'mindee';
}

angular.module('FieldModule').component('mindee', <StandardComponent> new MindeeComponent);