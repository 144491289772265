import * as angular from 'angular';
import jQuery from "jquery";
import StatusService from "components/blocworx/services/status.service";

declare const window: any;

"use strict";

angular.module('FieldModule')
    .controller('SubmitButtonFieldController', SubmitButtonFieldController);

// Injection of each class
SubmitButtonFieldController.$inject = ['$rootScope', '$scope', 'ScopeAndLocal', 'sound', 'status', 'Blocworx', 'SubmitButtonFieldFactory', '$window'];

// Controller Constructor
function SubmitButtonFieldController($rootScope, $scope, ScopeAndLocal, sound, status, Blocworx, SubmitButtonFieldFactory, $window) {

    let vm = this;

    vm.tokenRef = localStorage.getItem('satellizer_token');

    vm.$onInit = async function () {
        try {
            ScopeAndLocal.startVariable(vm, $scope, 'action');
            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            ScopeAndLocal.startVariable(vm, $scope, 'data');
            ScopeAndLocal.startVariable(vm, $scope, 'fc');
            ScopeAndLocal.startVariable(vm, $scope, 'field');
            ScopeAndLocal.startVariable(vm, $scope, 'row');
            ScopeAndLocal.startVariable(vm, $scope, 'main');
            vm.showBulkUpload = [];

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Set what is the active field for the controller build of this
     * component.
     * @param fieldName
     */
    vm.activeField = (fieldName) => {
        vm.scanStation.activeField = fieldName;
    }

    /**
     * This will do the check to see if this filed should
     * show or hide.
     * @param fieldType
     */
    vm.showField = (fieldType) => {
        return vm.scanStation.newField.field_type === fieldType;
    }



}

export default SubmitButtonFieldController;