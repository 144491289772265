import * as angular from 'angular';
import * as $ from "jquery";

"use strict";

angular
    .module('FieldModule')
    .factory('SubBlocFieldFactory', SubBlocFieldFactory);


SubBlocFieldFactory.$inject = ['$window'];

/**
 * Object that will create a Plain Data Factory, all methods of factory
 * will be available from outside.
 */
function SubBlocFieldFactory($window) {
    let factory = this;

    /**
     * Method responsible for processing behaviour after submitting data
     * @param field
     * @returns {*}
     */

    factory.processAfterSubmitDataBehaviour = function (field) {
        field.processAfterSubmitEvents = true;
        window.parent.postMessage(field, '*');
    }

    /**
     * Method responsible for processing behaviour after a manual trigger
     * @param field
     * @returns {*}
     */

    factory.processManualCloseBehaviour = function (field) {
        field.processManualEvents = true;
        window.parent.postMessage(field, '*');
    }

    factory.triggerJQueryReOpeningActions = function(field) {
        // because the iframe is sitting inside the bloc, if the bloc is visually hidden we must manually unhide and rehide certain parts

        if($('#scanStationForm-' + field.data.closeSubBlocAndReOpenFieldId).hasClass('hide-in-form')) {
            $('#scanStationForm-' + field.data.closeSubBlocAndReOpenFieldId).removeClass('hide-in-form');
            $('#scanStationForm-' + field.data.closeSubBlocAndReOpenFieldId).addClass('hide-in-form-sub-bloc-accordian');
        }

        $('#scanStationFormContainer-' + field.data.id + ' .close-popup').trigger('click');
        $('#scanStationFormContainer-' + field.data.closeSubBlocAndReOpenFieldId + ' .box-title').trigger('click');

    }

    /**
     * Method for when we are in a sub bloc and we want to send data to the parent
     * @param data
     * @param field
     * @returns {*}
     */

    factory.sendDataToParent =  function(data, field) {
        let dataToSend = {
            subBlocChildToParentData: data,
            subBlocChildToParentField: field
        }
        window.parent.postMessage(dataToSend, '*');
    }



    $window.addEventListener('message', async function (field) {
        try {


            if (field.data.processAfterSubmitEvents == true) {

                if(field.data.closeTheSubBlocOnly == 1) {
                    $('#scanStationFormContainer-' + field.data.id + ' .close-popup').trigger('click');
                }

                if(field.data.closeTheSubBlocAndSubmitParentForm == 1) {
                    $('#scanStationFormContainer-' + field.data.id + ' .close-popup').trigger('click');
                    $('.submit-button').trigger('click');
                }

                if((field.data.closeSubBlocAndReOpenAnotherSubBlocAfterSubmit ==1) && field.data.closeSubBlocAndReOpenFieldId != null) {
                    factory.triggerJQueryReOpeningActions(field);
                }

            }

            if(field.data.processManualEvents == true) {
                if(field.data.closeSubBlocAndReOpenAnotherSubBlocUsingSeperateButton ==1) {
                    factory.triggerJQueryReOpeningActions(field);
                }

            }


         } catch (e) {
            console.log(e);
        }

    });

    return factory;
}

// We are inside a sub form listening for postMessage data to update values locally



