import * as angular from 'angular';
import * as $ from "jquery";
import {AllDataInterface} from "../../../legacy/controllers/AllData.interface";

"use strict";

angular
    .module('FieldModule')
    .factory('EChartFactory', EChartFactory);


EChartFactory.$inject = ['$http', 'Configuration'];


/**
 * Object that will create an Echart Factory, all methods of factory
 * will be available from outside.
 */
function EChartFactory($http, Configuration) {
    let factory = this;

    /**
     * Method responsible for removing a count item from the chart field builder
     * @param countLineItemsArray
     * @param index
     * @returns {*}
     */

    factory.removeChartCountItemFromArray = function(countLineItemsArray, index) {
        delete countLineItemsArray[index];
        return countLineItemsArray;
    }

    /**
     * Method responsible for setting the field parameters from edit
     * @param fieldParameters
     * @returns {*}
     */

    factory.setInitialFieldParametersFromEdit = function (fieldParameters) {

        var parametersToReturn = {};

        return parametersToReturn;
    }

    /**
     * Method responsible for building and editing the X Axis Local Fields to watch
     * @param XAxisLocalFieldsArray
     * @param action
     * @param XAxisLocalField
     * @param index
     * @returns {*}
     */
    factory.addRemoveToXAxisLocalFields = function (XAxisLocalFieldsArray, action, XAxisLocalField, index = null) {
        if((XAxisLocalField == '' || typeof XAxisLocalField == 'undefined') && action == 'add') {
            alert('Please Select a field');
            return XAxisLocalFieldsArray;
        }
        if(typeof XAxisLocalFieldsArray == 'undefined') {
            XAxisLocalFieldsArray = {};
        }

        if(action == 'add') {

            XAxisLocalFieldsArray[Object.keys(XAxisLocalFieldsArray).length] = XAxisLocalField;

        } else {
            delete XAxisLocalFieldsArray[index];
            // build new object with fresh keys to keep it in sync
            var XAxisLocalFieldsArrayForLoop = XAxisLocalFieldsArray;
            XAxisLocalFieldsArray = {};
            var i = 0;
            angular.forEach(XAxisLocalFieldsArrayForLoop, function(value) {
                XAxisLocalFieldsArray[i] = value;
                i++;
            });
        }

        return XAxisLocalFieldsArray;

    }

    /**
     * Method responsible for building and editing the list of X Axis Permanent Values
     * @param XAxisPermanentValuesArray
     * @param action
     * @param XAxisPermanentValue
     * @param index
     * @returns {*}
     */
    factory.addOrRemoveXAxisPermanentValues = function (XAxisPermanentValuesArray, action, XAxisPermanentValue, index = null) {
        if((XAxisPermanentValue == '' || typeof XAxisPermanentValue == 'undefined') && action == 'add') {
            alert('Please Enter a value');
            return XAxisPermanentValuesArray;
        }
        if(typeof XAxisPermanentValuesArray == 'undefined') {
            XAxisPermanentValuesArray = {};
        }

        if(action == 'add') {

            XAxisPermanentValuesArray[Object.keys(XAxisPermanentValuesArray).length] = XAxisPermanentValue;

        } else {
            delete XAxisPermanentValuesArray[index];
            // build new object with fresh keys to keep it in sync
            var XAxisPermanentValuesArrayForLoop = XAxisPermanentValuesArray;
            XAxisPermanentValuesArray = {};
            var i = 0;
            angular.forEach(XAxisPermanentValuesArrayForLoop, function(value) {
                XAxisPermanentValuesArray[i] = value;
                i++;
            });
        }

        $('#new-permanent-value').trigger('focus');


        return XAxisPermanentValuesArray;

    }

    /**
     * This is to get all the count data required to build a chart
     * @param fieldId
     * @param allFieldsData
     * @returns {*}
     */
    factory.getEChartData = async function (fieldId, allFieldsData) {

        let apiBase = Configuration.getApiUrl() + 'get-echart-data';
        let data = {
            fieldId: fieldId,
            allFieldsData: allFieldsData
        };
        return $http.post(apiBase, data);
    };

    /**
     * Replicates the getAllFieldsData function from ScanStationCtrl
     * @param scanStationDataToAdd
     * @param scanStationFields
     * @returns {*}
     */
    factory.getAllFieldsData = async function (scanStationDataToAdd, scanStationFields) {

        let allFieldsData = {};
        let dataToAdd = <AllDataInterface>{};
        angular.forEach(scanStationFields, (field) => {

            let dataToAdd = {
                data: scanStationDataToAdd[field.field_slug],
                slug: field.field_slug,
            };
            allFieldsData[field.id] = dataToAdd;
        });

        return allFieldsData;

    }

    return factory;
}

