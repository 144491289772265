import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
    .controller('FieldController', FieldController);

FieldController.$inject = [
    'ImageUrlFieldService',
    'TotalResultsField',
    'PieChartField',
    'Configuration',
    '$scope',
    'MultiButtonFactory',
    'PlainDataFieldFactory',
    'DataFromStationFieldsFactory',
    'scanStation',
    '$timeout',
    'PieChartFactory',
    'AutoGenerateFieldFactory',
    'dropdownFromListFactory',
    'SubFormFieldFactory',
    'FormListFieldFactory',
    'fieldParameterFactory',
    'EChartField',
    'EChartFactory',
    'DigitalSignatureFieldFactory',
    'FieldFactory',
    'CrossBlocDataUpdatesFieldFactory',
    'SubmitButtonFieldFactory',
    'BarcodeGeneratorFactory',
    'resultsBoxField'
];

// Controller Constructor
function FieldController(ImageUrlFieldService,
                         TotalResultsField,
                         PieChartField,
                         Configuration,
                         $scope,
                         MultiButtonFactory,
                         PlainDataFieldFactory,
                         DataFromStationFieldsFactory,
                         scanStation,
                         $timeout,
                         PieChartFactory,
                         AutoGenerateFieldFactory,
                         dropdownFromListFactory,
                         SubFormFieldFactory,
                         FormListFieldFactory,
                         fieldParameterFactory,
                         EChartField,
                         EChartFactory,
                         DigitalSignatureFieldFactory,
                         FieldFactory,
                         CrossBlocDataUpdatesFieldFactory,
                         SubmitButtonFieldFactory,
                         BarcodeGeneratorFactory,
                         resultsBoxField) {


    let vm = this;
    vm.initialFieldParams = {};
    const version = vm.version = Configuration.getVersion();
    vm.jobScanStations = {};
    vm.fieldParameterToProcess = '';
    vm.initialFieldParamsPassed = {};

    vm.inputFields = ['text', 'textarea', 'button', 'multi-button', 'checkbox', 'numeric', 'textarea', 'dropdown-from-list', 'date-time-selector', 'qrcode', 'bluetooth'];
    vm.fileFields = ['file', 'multi-file', 'image-url-field'];
    vm.linkedDataSelectionFields = ['dropdown-from-station', 'autocomplete-field', 'radio-buttons-from-station', 'checkbox-buttons-from-station', 'link-data-field'];
    vm.contentEditingComponents = ['wysiwyg', 'wysiwyg-for-user', 'seperator', 'html'];
    vm.userBasedComponents = ['user-select', 'logged-in-user-info', 'digital-signature', 'create-user-popup'];
    vm.dynamicOutputFields = ['plain-data', 'label', 'time-diff', 'maths', 'auto-generate-full-timestamp', 'auto-generate-time', 'auto-generate-date', 'auto-generate-number', 'auto-generate-number-text', 'auto-generate-number-date', 'auto-generate-number-text-date', 'total-results', 'field-merge-builder', 'barcode-generator'];
    vm.chartsResultsDataFeeds = ['results-box', 'pie-chart', 'echart'];
    vm.subBlocsToolsAndOther = ['formception-2', 'jobs-list-dropdown', 'alert-button', 'data-to-form', 'cross-bloc-data-updates', 'submit-button-field', 'mail-alert'];
    vm.thirdPartyIntegrationComponents = ['blocworx-gateway','bartender-file-generator', 'file-builder','mindee'];

    vm.$onInit = async function () {

        try {
            vm.imageUrlField = ImageUrlFieldService;
            vm.totalResultsField = TotalResultsField;
            vm.pieChartField = PieChartField;
            vm.eChartField = EChartField;
            vm.modalStatus = 'modal-off'

            $scope.selectedJobId = null;
            $scope.fieldParameterToProcess = vm.fieldParameterToProcess;
            $scope.initialFieldParamsPassed = vm.initialFieldParamsPassed;
        } catch ( e ) {
            console.log(e);
        }
    }

    // check if string from data result is url or not
    vm.isValidHttpUrl = function (string) {
        try
        {
            let url;
            url = new URL(string);
            return url.protocol === "http:" || url.protocol === "https:";

        } catch (e) {
            return false;
        }
    }

    vm.addOrRemoveButtonForMultiButton = async function (action, button, index = null) {
        vm.initialFieldParams.multiButtonListArray = await MultiButtonFactory.addOrRemoveButtonForMultiButton(vm.initialFieldParams.multiButtonListArray, action, button, index)
        vm.updateMultiButtonListFromArray();
        vm.newButtonName = '';
        $scope.$apply();
    }

    vm.addOrRemoveFromDropDownList = async function (action, dropdownItem, index = null) {
        vm.initialFieldParams.dropdownFromListArray = await dropdownFromListFactory.addOrRemoveFromDropDownList(vm.initialFieldParams.dropdownFromListArray, action, dropdownItem, index)
        vm.updateDropdownFromListFromArray();
        vm.newDropDownItem = '';
        $scope.$apply();
    }


    vm.addRemoveToXAxisLocalFields = async function (action, XAxisLocalField, index = null) {
        vm.initialFieldParams.XAxisLocalFieldsArray = await EChartFactory.addRemoveToXAxisLocalFields(vm.initialFieldParams.XAxisLocalFieldsArray, action, XAxisLocalField, index)
        vm.initialFieldParams.XAxisLocalFields = JSON.stringify(vm.initialFieldParams.XAxisLocalFieldsArray);
        $scope.$apply();
    }

    vm.addEChartNewLineItem = async function () {
        if (typeof (vm.initialFieldParams.countLineItemsArray) == 'undefined') {
            vm.initialFieldParams.countLineItemsArray = [];
        }

        if (typeof (vm.initialFieldParams.countLineItemsArray) == 'object') {
            let countLineItemsArray = [];
            angular.forEach(vm.initialFieldParams.countLineItemsArray, function (value) {
                countLineItemsArray.push(value);
            });

            vm.initialFieldParams.countLineItemsArray = countLineItemsArray;
        }

        // TODO improve the job look up component so we dont have to carry the jobId into initialFieldParams in this case
        if (typeof (vm.initialFieldParams.eChartLineItemJobId) != 'undefined' && typeof (vm.initialFieldParams.eChartLineItemJobId.jobId) != 'undefined') {
            vm.newEChartLineItem.jobId = vm.initialFieldParams.eChartLineItemJobId.jobId;
        } else {
            vm.newEChartLineItem.jobId = vm.jobId;

        }

        delete vm.initialFieldParams.eChartLineItemJobId;

        if (vm.newEChartLineItem.countAll == 1) {
            delete vm.newEChartLineItem.remoteFieldId;
            delete vm.newEChartLineItem.localFieldId;
        }

        vm.initialFieldParams.countLineItemsArray.push(angular.copy(vm.newEChartLineItem));
        vm.newEChartLineItem = {};
        vm.eChartNewCountItem = false;
    }

    vm.addOrRemoveXAxisPermanentValues = async function (action, XAxisPermanentValue, index = null) {
        vm.initialFieldParams.XAxisPermanentValuesArray = await EChartFactory.addOrRemoveXAxisPermanentValues(vm.initialFieldParams.XAxisPermanentValuesArray, action, XAxisPermanentValue, index);
        vm.initialFieldParams.XAxisPermanentValues = JSON.stringify(vm.initialFieldParams.XAxisPermanentValuesArray);
        vm.XAxisPermanentValue = '';
        $scope.$apply();
    }

    vm.addOrRemoveDataFromAnotherStationMatchingFieldPairs = async function (action, localFieldId, remoteFieldId, index = null) {
        vm.initialFieldParams.localFieldMatchingFieldPairsArray = await DataFromStationFieldsFactory.addOrRemoveDataFromAnotherStationMatchingFieldPairs(vm.initialFieldParams.localFieldMatchingFieldPairsArray, action, localFieldId, remoteFieldId, index);
        await vm.updateDataFromStationLocalFieldMatchingPairsFromArray();
        $scope.$apply();
    }

    vm.addOrRemoveExclusionDataFromAnotherStationMatchingFieldPairs = async function (action, localFieldId, remoteFieldId, index = null) {
        vm.initialFieldParams.localFieldExclusionMatchingFieldPairsArray = await DataFromStationFieldsFactory.addOrRemoveDataFromAnotherStationMatchingFieldPairs(vm.initialFieldParams.localFieldExclusionMatchingFieldPairsArray, action, localFieldId, remoteFieldId, index);
        await vm.updateExclusionDataFromStationLocalFieldMatchingPairsFromArray();
        $scope.$apply();
    }

    // sending data from parent to child fields in sub blocs
    vm.addOrRemoveSubFormParentChildFields = async function (action, parentFieldId, childFieldId, index = null) {
        vm.initialFieldParams.subFormParentChildFieldsArray = await SubFormFieldFactory.addOrRemoveSubFormParentChildFields(vm.initialFieldParams.subFormParentChildFieldsArray, action, parentFieldId, childFieldId, index);
        await vm.updateSubFormParentChildFieldsFromArray();
        $scope.$apply();
    }

    // sending data from children to parent fields in sub blocs
    vm.addOrRemoveSubFormSendingChildToParentFields = async function (action, childFieldId, parentFieldId, index = null) {
        vm.initialFieldParams.subFormSendChildDataToParentFieldsArray = await SubFormFieldFactory.subFormSendChildToParentParentSelectedChildField(vm.initialFieldParams.subFormSendChildDataToParentFieldsArray, action, childFieldId, parentFieldId, index);
        await vm.updateSubFormSendChildToParentParentSelectedChildFieldFromArray();
        $scope.$apply();
    }

    vm.addOrFormListLocalRemoteFields = async function (action, formListLocalRemoteSelectedLocalField, remoteFieldSlug, index = null) {
        vm.initialFieldParams.formListLocalRemoteFieldsArray = await FormListFieldFactory.addOrFormListLocalRemoteFields(vm.initialFieldParams.formListLocalRemoteFieldsArray, action, formListLocalRemoteSelectedLocalField, remoteFieldSlug, index);
        vm.updateFormListLocalRemoteFieldsFromArray();
        $scope.$apply();
    }

    vm.addOrRemoveLatestDataFormListSlugs = async function (action, formListLatestDataFieldSlug, index = null) {
        vm.initialFieldParams.formListLatestDataSlugs = await FormListFieldFactory.addOrRemoveLatestDataFormListSlugs(vm.initialFieldParams.formListLatestDataSlugs, action, formListLatestDataFieldSlug, index);
        $scope.$apply();
    }

    vm.addOrRemoveLatestDataFormListSlugFilters = async function (action, formListLatestDataFieldSlugFilter, index = null) {
        vm.initialFieldParams.formListLatestDataSlugFiltersArray = await FormListFieldFactory.addOrRemoveLatestDataFormListSlugFilters(vm.initialFieldParams.formListLatestDataSlugFiltersArray, action, formListLatestDataFieldSlugFilter, index);
        // TODO remove the fact we are duplicating parameters, its related to an array and a JSON object
        vm.initialFieldParams.formListLatestDataSlugFilters = angular.copy(vm.initialFieldParams.formListLatestDataSlugFiltersArray);
        $scope.$apply();
    }


    vm.updateCrossBlocDataFieldPairsToCheckAgainst = async function (action, localFieldId, remoteFieldId, index = null) {
        vm.initialFieldParams.localFieldMatchingFieldPairsArrayToCheckAgainst = await CrossBlocDataUpdatesFieldFactory.addOrRemoveMatchingFieldPairs(vm.initialFieldParams.localFieldMatchingFieldPairsArrayToCheckAgainst, action, localFieldId, remoteFieldId, index);

        // we be able to get rid of this code
        let localFieldMatchingFieldPairsArrayToCheckAgainst = angular.copy(vm.initialFieldParams.localFieldMatchingFieldPairsArrayToCheckAgainst);
        angular.forEach(localFieldMatchingFieldPairsArrayToCheckAgainst, function (fieldPairMatch) {
            delete fieldPairMatch.localFieldName;
            delete fieldPairMatch.remoteFieldName;
        });

        vm.initialFieldParams.localFieldMatchingFieldPairsToCheckAgainst = JSON.stringify(localFieldMatchingFieldPairsArrayToCheckAgainst);

        $scope.$apply();
    }


    vm.updateCrossBlocDataFieldPairsToUpdate = async function (action, localFieldId, remoteFieldId, index = null) {
        vm.initialFieldParams.localFieldMatchingFieldPairsArrayToUpdate = await CrossBlocDataUpdatesFieldFactory.addOrRemoveMatchingFieldPairs(vm.initialFieldParams.localFieldMatchingFieldPairsArrayToUpdate, action, localFieldId, remoteFieldId, index);

        // we be able to get rid of this code
        let localFieldMatchingFieldPairsArrayToUpdate = angular.copy(vm.initialFieldParams.localFieldMatchingFieldPairsArrayToUpdate);
        angular.forEach(localFieldMatchingFieldPairsArrayToUpdate, function (fieldPairMatch) {
            delete fieldPairMatch.localFieldName;
            delete fieldPairMatch.remoteFieldName;
        });

        vm.initialFieldParams.localFieldMatchingFieldPairsToUpdate = JSON.stringify(localFieldMatchingFieldPairsArrayToUpdate);

        $scope.$apply();
    }

    vm.updateCrossBlocDataFieldPairsToUpdateForAddingNewIfNoRecord = async function (action, localFieldId, remoteFieldId, index = null) {
        vm.initialFieldParams.localFieldMatchingFieldPairsArrayToAddNewIfNoRecord = await CrossBlocDataUpdatesFieldFactory.addOrRemoveMatchingFieldPairs(vm.initialFieldParams.localFieldMatchingFieldPairsArrayToAddNewIfNoRecord, action, localFieldId, remoteFieldId, index);

        // we be able to get rid of this code
        let localFieldMatchingFieldPairsArrayToAddNewIfNoRecord = angular.copy(vm.initialFieldParams.localFieldMatchingFieldPairsArrayToAddNewIfNoRecord);
        angular.forEach(localFieldMatchingFieldPairsArrayToAddNewIfNoRecord, function (fieldPairMatch) {
            delete fieldPairMatch.localFieldName;
            delete fieldPairMatch.remoteFieldName;
        });

        vm.initialFieldParams.localFieldMatchingFieldPairsToAddNewIfNoRecord = JSON.stringify(localFieldMatchingFieldPairsArrayToAddNewIfNoRecord);

        $scope.$apply();
    }

    vm.addOrRemoveChartField = async function (action, fieldId, index = null) {
        vm.initialFieldParams.chartFieldListArray = await PieChartFactory.addOrRemovePieFromChartList(vm.initialFieldParams.chartFieldListArray, action, fieldId, index)
        vm.updateChartFieldListFromArray();
        $scope.$apply();
    }

    vm.removeChartCountItemFromArray = async function (index) {
        vm.initialFieldParams.countLineItemsArray = await EChartFactory.removeChartCountItemFromArray(vm.initialFieldParams.countLineItemsArray, index);
        $scope.$apply();
    }

    vm.setInitialFieldParametersFromEdit = async function (field) {

        vm.initialFieldParams = await fieldParameterFactory.setInitialFieldParametersFromEdit(field);

        // TODO Refactor this to bring them all into the fieldParameterFactory like above

        $scope.$apply();

        if (field.field_type == 'multi-button') {
            vm.initialFieldParams.multiButtonListArray = await MultiButtonFactory.setInitialFieldParametersFromEdit(field.parameters);
            vm.updateMultiButtonListFromArray();
            $scope.$apply();
        }

        if (field.field_type == 'dropdown-from-list') {
            vm.initialFieldParams.dropdownFromListArray = await dropdownFromListFactory.setInitialFieldParametersFromEdit(field.parameters);
            vm.updateDropdownFromListFromArray();
            $scope.$apply();
        }

        if (field.field_type == 'plain-data') {
            vm.initialFieldParams.plainDataFieldValue = await PlainDataFieldFactory.setInitialFieldParametersFromEdit(field.parameters);
            $scope.$apply();
        }

        if (field.field_type == 'dropdown-from-station' || field.field_type == 'autocomplete-field' || field.field_type == 'radio-buttons-from-station'
            || field.field_type == 'checkbox-buttons-from-station') {
            // TODO figure out how to remove setTimeOut, currently a very strange issue is happening where fieldIdToLookUp is showing as null, this is the only fix I can see working
            setTimeout(async function() {
                vm.initialFieldParams = await DataFromStationFieldsFactory.setInitialFieldParametersFromEdit(field.parameters);
                await vm.updateDataFromStationLocalFieldMatchingPairsFromArray();
                $scope.$applyAsync();
                $scope.$apply();
            },500);
        }

        if (field.field_type == 'checkbox' || field.field_type == 'checkbox-dynamic-checked-value' || field.field_type == 'button') {
            vm.initialFieldParams.pressedUnpressedValues = {};
            vm.initialFieldParams.pressedUnpressedValues.pressed = field.pressedUnpressedValues.pressed;
            vm.initialFieldParams.pressedUnpressedValues.unPressed = field.pressedUnpressedValues.unPressed;
            if (typeof field.populateCheckedValueElseWhereFieldID != 'undefined') {
                vm.initialFieldParams.populateCheckedValueElseWhereFieldID = field.populateCheckedValueElseWhereFieldID;
            }
        }

        if (field.field_type == 'maths') {
            vm.initialFieldParams.mathsEquation = field.mathsEquation;
            if (typeof field.syntaxErrorDefaultText != 'undefined') {
                vm.initialFieldParams.syntaxErrorDefaultText = field.syntaxErrorDefaultText;
            } else {
                vm.initialFieldParams.syntaxErrorDefaultText = 'Syntax Error';

            }
        }

        if (field.field_type == 'pie-chart') {
            vm.initialFieldParams.chartFieldListArray = await PieChartFactory.setInitialFieldParametersFromEdit(field.parameters);
            vm.updateChartFieldListFromArray();
            $scope.$apply();
        }

        if (field.field_type == 'echart') {

        }

        if (field.field_type == 'wysiwyg' || field.field_type == 'html') {
            vm.initialFieldParams.htmlContent = field.htmlContent;
        }

        if (field.field_type == 'field-merge-builder') {
            vm.initialFieldParams.fieldMergeFormat = field.fieldMergeFormat;
        }

        if (field.field_type == 'seperator') {
            vm.initialFieldParams.seperatorDefaultText = field.seperatorDefaultText;
        }

        if (field.field_type == 'label') {
            vm.initialFieldParams.labelText = field.labelText;
        }

        if (field.field_type == 'date-time-selector') {
            vm.initialFieldParams.dateTimeSetting = field.dateTimeSetting;
        }

        if (field.field_type == 'wysiwyg-for-user') {
            vm.initialFieldParams.openEditorText = field.openEditorText;
        }

        if (field.field_type == 'digital-signature') {
            vm.initialFieldParams.defaultText = field.defaultText;
        }


        if (field.field_type == 'total-results') {
            vm.initialFieldParams.totalResultsAssociatedFields = field.totalResultsAssociatedFields;
        }

        if (field.field_type == 'bartender-file-generator') {
            vm.initialFieldParams.bartenderSftpUserName = field.bartenderSftpUserName;
            vm.initialFieldParams.bartenderFileName = field.bartenderFileName;
            vm.initialFieldParams.bartenderFieldSlugs = field.bartenderFieldSlugs;
        }

        if (field.field_type == 'file-builder') {
            let fieldData = field[field.field_type];
            vm.initialFieldParams[field.field_type]=fieldData;
            vm.initialFieldParams.filename = field.filename;
            vm.initialFieldParams.path = field.path;
            vm.initialFieldParams.buttonText = field.buttonText;
            vm.initialFieldParams.buttonColour = field.buttonColour;
            vm.initialFieldParams.fontAwesomeIcon = field.fontAwesomeIcon;
            vm.initialFieldParams.externalJobKeyword = field.externalJobKeyword;
            vm.initialFieldParams.dropdownFromStationLookUp = field.dropdownFromStationLookUp;
            vm.initialFieldParams.localFieldMatchingFieldPairsArray = field.localFieldMatchingFieldPairsArray;
            vm.initialFieldParams.filebuilder = true;

            let fieldsArray = [];
            for (const property in field.exportedFieldsArray) {
                fieldsArray = [...fieldsArray, JSON.parse(JSON.stringify(field.exportedFieldsArray[property]))];
            }
            vm.initialFieldParams.exportedFieldsArray = fieldsArray;
        }

        if(field.field_type == 'mail-alert'){
            vm.initialFieldParams.mailAlertConditions = field.mailAlertConditions
        }

        if (field.field_type.includes('auto-generate')) {
            vm.initialFieldParams = await AutoGenerateFieldFactory.setInitialFieldParametersFromEdit(field.parameters, vm.initialFieldParams);
            $scope.$apply();
        }

        if (field.field_type.includes('time-diff')) {
            vm.initialFieldParams.timeOrDateDifferenceOption = field.timeOrDateDifferenceOption;
            vm.initialFieldParams.startAndFinishFields = field.startAndFinishFields;
            vm.initialFieldParams.timeDateDifferenceFormat = field.timeDateDifferenceFormat;
            vm.initialFieldParams.timeDateDifferenceFormatForEndField = field.timeDateDifferenceFormatForEndField;
            vm.initialFieldParams.defaultResultFormat = field.defaultResultFormat;
        }

        if (field.field_type == 'formception-2') {
            vm.initialFieldParams = await SubFormFieldFactory.setInitialFieldParametersFromEdit(field.parameters, vm.initialFieldParams);
            await vm.updateSubFormParentChildFieldsFromArray();
            await vm.updateSubFormSendChildToParentParentSelectedChildFieldFromArray();
            $scope.$apply();
        }

        if (field.field_type == 'results-box') {
            vm.initialFieldParams.resultsBoxStationAndFields = field.resultsBoxStationAndFields;
            vm.initialFieldParams.resultsBoxReverseOrder = field.resultsBoxReverseOrder;

        }

        if (field.field_type == 'data-to-form') {
            if (typeof field.linkedStationDetails != 'undefined') {
                vm.initialFieldParams.linkedStationDetails = field.linkedStationDetails;
            }

            if (typeof field.formListLocalRemoteFieldsArray != 'undefined') {
                vm.initialFieldParams.formListLocalRemoteFieldsArray = field.formListLocalRemoteFieldsArray;
                vm.updateFormListLocalRemoteFieldsFromArray()
            }

            if (typeof field.formListLatestDataSlugs != 'undefined') {
                vm.initialFieldParams.formListLatestDataSlugs = vm.initialFieldParams.formListLatestDataSlugs;
            }

            vm.initialFieldParams.formDisplayOption = field.formDisplayOption;
            vm.initialFieldParams.formListJobAndStationId = {};
            vm.initialFieldParams.formListJobAndStationId.jobId = field.formListJobAndStationId.jobId;
        }

        return true;
    }

    vm.getJobScanStations = function (paramSetting) {
        try {
            $scope.$applyAsync(async function () {

                if (typeof vm.initialFieldParams[paramSetting] != 'undefined' && typeof vm.initialFieldParams[paramSetting].jobId != 'undefined') {
                    var jobId = vm.initialFieldParams[paramSetting].jobId;
                } else {
                    var jobId = vm.currentJobId
                }

                let data = await scanStation.getJobScanStations(jobId, null);

                // TODO figure out why scanStationId is resetting, preserving here

                if (typeof (vm.initialFieldParams[paramSetting]) != 'undefined' && typeof vm.initialFieldParams[paramSetting].stationId != 'undefined') {
                    var scanStationId = vm.initialFieldParams[paramSetting].stationId;
                }

                vm.jobScanStations = data.data.data;

                // First Apply the jobScanStations
                $scope.$apply();

                if (typeof (vm.initialFieldParams[paramSetting]) != 'undefined') {
                    vm.initialFieldParams[paramSetting].stationId = scanStationId;
                }

                // Now apply the scanStationId

                $scope.$apply();
            });


        } catch ( e ) {
            console.log(e);
        }

    };

    vm.getScanStationFields = async function (stationId) {
        try {

            // TODO find out how to pass stationId in for ng-init using async
            if (stationId != null) {
                let reload = true;
                let data = await scanStation.getScanStationData(stationId, 'rule-query', reload);
                vm.scanStationFieldsForRule = data.data.scanStationFields;
                vm.scanStationFieldsForRuleIdsToField = [];
                angular.forEach(vm.scanStationFieldsForRule, function (value, index) {
                    vm.scanStationFieldsForRuleIdsToField[value.id] = value;
                });
                $scope.$apply();
            }

        } catch ( e ) {
            console.log(e);
        }
    }

    vm.updateMultiButtonListFromArray = function () {
        vm.initialFieldParams.multiButtonList = JSON.stringify(vm.initialFieldParams.multiButtonListArray);
    }

    vm.updateDropdownFromListFromArray = function () {
        vm.initialFieldParams.dropdownFromList = JSON.stringify(vm.initialFieldParams.dropdownFromListArray);
    }

    vm.updateChartFieldListFromArray = function () {
        vm.initialFieldParams.chartFieldList = JSON.stringify(vm.initialFieldParams.chartFieldListArray);
    }

    vm.updateDataFromStationLocalFieldMatchingPairsFromArray = async function () {
        let localFieldMatchingFieldPairsArray = angular.copy(vm.initialFieldParams.localFieldMatchingFieldPairsArray);
        angular.forEach(localFieldMatchingFieldPairsArray, function (fieldPairMatch) {
            delete fieldPairMatch.localFieldName;
            delete fieldPairMatch.remoteFieldName;
        });

        vm.initialFieldParams.localFieldMatchingFieldPairs = JSON.stringify(localFieldMatchingFieldPairsArray);

    }

    vm.updateExclusionDataFromStationLocalFieldMatchingPairsFromArray = async function () {
        let localFieldExclusionMatchingFieldPairsArray = angular.copy(vm.initialFieldParams.localFieldExclusionMatchingFieldPairsArray);
        angular.forEach(localFieldExclusionMatchingFieldPairsArray, function (fieldPairMatch) {
            delete fieldPairMatch.localFieldName;
            delete fieldPairMatch.remoteFieldName;
        });

        vm.initialFieldParams.localFieldExclusionMatchingFieldPairs = JSON.stringify(localFieldExclusionMatchingFieldPairsArray);

    }

    vm.updateSubFormParentChildFieldsFromArray = async function () {
        let subFormParentChildFieldsArray = angular.copy(vm.initialFieldParams.subFormParentChildFieldsArray);
        angular.forEach(subFormParentChildFieldsArray, function (fieldPairMatch) {
            delete fieldPairMatch.parentFieldName;
            delete fieldPairMatch.childFieldName;
        });

        vm.initialFieldParams.subFormParentChildFields = JSON.stringify(subFormParentChildFieldsArray);
    }

    vm.updateSubFormSendChildToParentParentSelectedChildFieldFromArray = async function () {
        let subFormSendChildDataToParentFieldsArray = angular.copy(vm.initialFieldParams.subFormSendChildDataToParentFieldsArray);
        angular.forEach(subFormSendChildDataToParentFieldsArray, function (fieldPairMatch) {
            delete fieldPairMatch.childFieldName;
            delete fieldPairMatch.parentFieldName;
        });

        vm.initialFieldParams.subFormSendChildDataToParentFields = JSON.stringify(subFormSendChildDataToParentFieldsArray);
    }

    vm.updateFormListLocalRemoteFieldsFromArray = function () {
        let formListLocalRemoteFieldsArray = angular.copy(vm.initialFieldParams.formListLocalRemoteFieldsArray);
        angular.forEach(formListLocalRemoteFieldsArray, function (fieldPairMatch) {
            delete fieldPairMatch.localFieldName;
            delete fieldPairMatch.remoteFieldName;
        });

        vm.initialFieldParams.formListLocalRemoteFields = JSON.stringify(formListLocalRemoteFieldsArray);

    }

    vm.attemptUserInFormAuthentication = async function (event, fieldSlug, fieldId, inFormUserAuthUsername, inFormUserAuthPassword) {
        if (event == 'button-click' || (event.keyCode != null && event.keyCode == 13)) {
            let response = await DigitalSignatureFieldFactory.authenticateUserInForm(fieldId, inFormUserAuthUsername, inFormUserAuthPassword);

            if (typeof response.data.failResponse != 'undefined') {
                alert(response.data.failResponse);
                vm.inFormUserAuthenticated[fieldId] = false;
            } else {
                if (typeof vm.inFormUserAuthenticated == 'undefined') {
                    vm.inFormUserAuthenticated = [];
                }
                vm.inFormUserAuthenticated[fieldId] = response.data.successValue;
            }

            $scope.$apply();
        }
    }

    vm.showFieldCase = async function (fieldsCategoryToCheck, fieldsCategoryCase, currentFieldType) {
        let showCase = await FieldFactory.showFieldCase(fieldsCategoryToCheck, currentFieldType);
        if (vm.showCase == null) {
            vm.showCase = {};
        }
        vm.showCase[fieldsCategoryCase] = showCase;
        return (showCase);
    }

    vm.updateShowCaseFieldType = async function (firstFieldInCategory, category) {
        vm.showCaseFieldType = firstFieldInCategory;
    }

    /**
     * Function that will be checking the scan station object uses_automatic_saving
     * if does, we can get the submited_status from the $row and return it
     * otherwise will return a non specific css rule resulting into a field
     * with no background changes on it.
     *
     * @param row
     * @param scanStationObj
     */
    vm.checkAutoSaveScanStation = (row, scanStationObj) => {
        if (scanStationObj.stationDetails.uses_automatic_saving === 1) {
            return `submitted-status-${row.submitted_status}`;
        }
        return `submitted-status-`;
    }

    /**
     * This method will be responsible for returning a initial field
     * with default option be returning true.
     * @param param
     */
    vm.checkField = (param, defaultResponse = 1) => {

        let valueToSet = defaultResponse;

        // we have the index, so must be a value
        if (param in vm.initialFieldParams) {

            if (vm.initialFieldParams[param]) {
                valueToSet = vm.initialFieldParams[param]
            }
        }

        return valueToSet;
    }

    /**
     * This method will make sure that we have or a date or time, we will be separating by sizes:
     *
     * Datetime case
     * size 19 => "2021-05-12 15:10:09"
     * size 18 => "2021-05-12 15:10:9" <-- this it will be result into "2021-05-12 15:10:09"
     *
     * Time case
     * size 8 => "15:10:09"
     * size 7 => "15:10:9" <-- this it will be result into "15:10:09"
     *
     * @param dateOrTime
     */
    vm.formatDateTime = (dateOrTime) => {

        try {
            let timestampSize = dateOrTime.length;

            // this is a timestamp case
            if (timestampSize >= 18) {

                if (timestampSize == 18) {
                    dateOrTime = dateOrTime.substring(0, dateOrTime.length - 1) + '0' + dateOrTime.substring(dateOrTime.length - 1)
                }

            } else if (timestampSize >= 7 && timestampSize <= 8) {

                const currentDate = new Date();
                const currentDayOfMonth = currentDate.getDate();
                const currentMonth = currentDate.getMonth();
                const currentYear = currentDate.getFullYear();

                let timeSplit = dateOrTime.split(':')
                let hour = timeSplit[0];
                let minutes = timeSplit[1];
                let seconds = timestampSize === 7 ? 0 + timeSplit[2] : timeSplit[2];

                dateOrTime = new Date(currentYear, currentMonth, currentDayOfMonth, hour, minutes, seconds);
                dateOrTime = dateOrTime.toLocaleTimeString(navigator.language, {
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                });
            }

            return dateOrTime

        } catch ( e ) {
            console.log(e);
        }

        return dateOrTime;
    }

    /**
     * Load the data of the result box field.
     * @param scanStation
     * @param field
     * @param row
     */
    vm.loadResultBox = async (scanStation, field, row) => {

        let filterDataToQuery = JSON.parse(row[field.field_slug]).filterDataToQuery;

        // loading the result box data
        await scanStation.loadResultBox(field, row, filterDataToQuery);
    }

    /**
     * This will check if can load the result box field.
     * @param scanStation
     * @param field_slug
     */
    vm.canLoadResultBox = (scanStation) => {
        return scanStation.resultBoxData !== undefined
    }


    /**
     * This is the case that will be able to show a text response in the results box
     * scan station data.
     *
     * @param field
     * @param scanStationData
     */
    vm.scanStationDataTextCase = (field, scanStationData) => {

        let valid = false;

        if(field.field_type != 'file' && field.field_type != 'digital-signature' && field.field_type != 'image-url-field'){
            valid = true
        }

        if(scanStationData[field.field_slug] == 'N/A'){
            valid = true
        }
        return valid;
    }

    /**
     * This is the case that we will be able to show an image in the results box
     * scan station data.
     *
     * @param field
     * @param scanStationData
     * @param scanStation
     */
    vm.scanStationDataImageCase = (field, scanStationData, scanStation) => {

        let valid = false;

        if (scanStationData[field.field_slug] != 'N/A')
        {
            if (field.field_type == 'digital-signature'){
                valid = true
            }

            if (field.field_type == 'image-url-field' ){
                valid = true
            }

            if(field.field_type == 'file' && scanStation.scanStationObj.stationDetails.show_previews_of_uploaded_images==1){
                valid = true
            }
        }

        return valid;
    }

    /**
     * This will open the modal.
     */
    vm.openModal = () => {
        vm.modalStatus = 'modal-on'
    }

    /**
     * This will close the modal
     */
    vm.closeModal = () => {
        vm.modalStatus = 'modal-off'
    }

    /**
     * This will be the rule to show or not the file preview.
     *
     * @param row
     * @param field
     * @param scanStation
     */
    vm.fileFieldShowPreviewsUploadImages = (row, field, scanStation) => {

        let showPreviewsUploadImages = false;

        if(field.field_type == 'file' && row[field.field_slug] != 'N/A'
            && scanStation != undefined
            && scanStation.scanStationObj != undefined
            && scanStation.scanStationObj.stationDetails != undefined
        ){
            if(scanStation.scanStationObj.stationDetails.show_previews_of_uploaded_images==0 || scanStation.scanStationObj.stationDetails.show_previews_of_uploaded_images == null ){
                showPreviewsUploadImages = false;
            } else {
                showPreviewsUploadImages = true;
            }
        }

        return showPreviewsUploadImages;
    }


    /**
     * This checks if on the row field element is
     * a file type, will return true if does otherwise false.
     *
     * @param row
     * @param field
     */
    vm.isFileTypeField = (row, field) => {
        return ! vm.isImageTypeField(row,field);
    }

    /**
     * This checks if on the row field element is
     * a file type, will return true if does otherwise false.
     *
     * @param row
     * @param field
     */
    vm.isImageTypeField = (row, field) => {

        let isImage = false;
        let dataToCheck = row[field.field_slug];
        let fileTypes = ['.tif', '.tiff', '.bmp', '.jpg','.jpeg','.gif','.png','.eps','.raw', '.nef']

        for (let index = 0; index < fileTypes.length -1; index++){
            if(dataToCheck.includes(fileTypes[index])){
                isImage = true;
                break;
            }
        }

        return isImage;
    }

    /**
     * This will be responsible to add a new field for the results box where the data only shows that field.
     *
     * @param field
     */
    vm.addNewResultsBoxOnlyShowField = async (field) => {
        vm.initialFieldParams.onlyShowFieldArray = await resultsBoxField.addNewResultsBoxOnlyShowField(vm.initialFieldParams.onlyShowFieldArray, field);
        $scope.$apply();
    }

    /**
     * This will be responsible to remove fields from the list where only certain fields are being shown.
     *
     * @param field
     */
    vm.removeResultsBoxOnlyShowField = async (index) => {
        vm.initialFieldParams.onlyShowFieldArray = await resultsBoxField.removeResultsBoxOnlyShowField(vm.initialFieldParams.onlyShowFieldArray, index);
        $scope.$apply();
    }

    /**
     * This will be responsible for setting values for colours for the results box
     *
     * @param value
     * @param colour
     */
    vm.addValueColourCombination = async (value, colour) => {
        vm.initialFieldParams.valueBasedCellColours = await resultsBoxField.addValueColourCombination(vm.initialFieldParams.valueBasedCellColours, value, colour);
        $scope.$apply();
    }


    /**
     * This will be responsible to remove combinations from the value/colour list
     *
     * @param field
     */
    vm.removeValueColourCombination = async (index) => {
        vm.initialFieldParams.valueBasedCellColours = await resultsBoxField.removeValueColourCombination(vm.initialFieldParams.valueBasedCellColours, index);
        $scope.$apply();
    }


    /**
     * Check to see if we can show the results box field or not
     */

    vm.canShowResultsBoxField = function(parseBoxResults, resultsBoxField, field) {

        // we are using the old method to check for hidden results box field, where its set using a hidden field parameter in the source bloc
        if(field.onlyShowFieldArray == null || (field.onlyShowFieldArray != null && Object.keys(field.onlyShowFieldArray).length == 0)) {
            if(parseBoxResults[field.field_slug].fieldHiddenOptions != null && parseBoxResults[field.field_slug].fieldHiddenOptions[resultsBoxField.field_slug] != null && parseBoxResults[field.field_slug].fieldHiddenOptions[resultsBoxField.field_slug].includes('results_box')) {
                return false;
            } else {
                return true;
            }
        } else {

            // this is the newer method for hiding/showing fields, where the hiding and showing can be set in the results box field instead of the source bloc

            let returnValue = false;

            angular.forEach(field.onlyShowFieldArray, function(fieldId) {
                if(resultsBoxField.id == parseFloat(fieldId)) {
                    returnValue = true;
                }
            });

            return returnValue;

        }
    }

    /**
     * This will give back a colour based on a value
     */

    vm.getResultsBoxColourFromData = function(valueBasedCellColours, value ) {

        let colourToReturn = '';

        if(valueBasedCellColours != null) {
            angular.forEach(valueBasedCellColours, function(combination) {
                if(combination.value == value) {
                    colourToReturn = combination.colour;
                }
            });
        }

        return colourToReturn;
    }



}

export default FieldController;