import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
    .controller('MatchingDataController', MatchingDataController);

// Injection of each class
MatchingDataController.$inject = [ '$scope', 'ScopeAndLocal', 'scanStation' ];

// Controller Constructor
function MatchingDataController($scope, ScopeAndLocal, scanStation) {

    let vm = this;

    vm.$onInit = async function () {
        try {

            ScopeAndLocal.startVariable(vm, $scope, 'fc');
            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            ScopeAndLocal.startVariable(vm, $scope, 'row');
            ScopeAndLocal.startVariable(vm, $scope, 'processField');

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This will get the length of objects
     * @param data
     */
    vm.getArrayLength = (data) => {
        let arraySize : number = 0;
        if(data !== undefined){
            arraySize = (typeof data == 'object') ?  Object.keys(data).length : data.length;
        }
        return arraySize;
    }

}

export default MatchingDataController;