import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";
import RadioButtonController from "./controllers/radioButton.controller";

"use strict";

class RadioButtonComponent extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/field/components/radioButton';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        scanStation: '<',
        field: '<',
        data: '<',
        main: '<',
        row: '<',
        fc: '<',
    }

    // This will set what will be the controller of this component
    public controller : any = RadioButtonController;

    // This will set an alias
    public controllerAs: string = 'rbc';
}

angular.module('FieldModule').component('radioButton', <StandardComponent> new RadioButtonComponent);