import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
       .service('ResultsFactory', ResultsFactory);

ResultsFactory.$inject = [];

/**
 * Service constructor.
 * @constructor
 */
function ResultsFactory() {
    let factory = this;


    return factory;

}