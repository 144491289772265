import * as angular from 'angular';
import ManageInactivityController from './controllers/manage-inactivity.controller';
import StandardComponent from 'components/standards/components/StandardComponent';

"use strict";

class ManageInactivityComponent extends StandardComponent {

    // This will enable the debug mode for this component
    public debug = false;
    public moduleFolder = 'components/manage-inactivity';

    /**
     * Please make sure that each function contains correct
     * documentation.
     */

    public bindings = {
        main: '<',
    }

    // This will set whatwill be the controller of this component
    public controller: any = ManageInactivityController;

    // This will set an alias
    public controllerAs: string = 'mi';
}

angular.module('ManageInactivityModule').component('manageInactivity', <StandardComponent> new ManageInactivityComponent);