import * as angular from 'angular';

"use strict";

angular.module('UrlModule')
       .service('UrlService', UrlService);

/**
 * Object that will be responsible to store all configuration's
 * data from the whole application
 *
 * @returns {Configuration}
 * @constructor
 */
function UrlService($state) {

    let service = this;
    // alert($state.current.name);
    console.log($state.current.name);

}
