import * as angular from 'angular';

"use strict";

angular.module('ScanStationModule')
       .controller('scanStationFieldController', ScanStationFieldController);

// Injection of each class
ScanStationFieldController.$inject = ['$scope'];

// Controller Constructor
function ScanStationFieldController($scope) {

    // Creating the view model reference
    let vm = this;

    vm.$onInit = async function () {
        try {


        } catch (e) {
            console.log(e);
        }

    }

}

export default ScanStationFieldController;