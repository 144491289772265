import * as angular from 'angular';

'use strict';

angular.module('ValidatorsModule')
       .provider('Validator', Validator);

/**
 * This will be responsible to implement filters on the
 * application.
 *
 * @constructor
 */
function Validator() {

    const service = this;

    service.$get = function() {

        return {
            scanStationEdit: service.scanStationEdit,
        }

    }

    /**
     * This is the main validator for the scanStationEdit action
     * First we need to check if we have both values from main and data
     * when we can find values it's the time to check if the users are ok
     * to access the screen.
     *
     * @param main
     * @param data
     */
    service.scanStationEdit = (main, data) => {

        if (main !== undefined && data !== undefined
            && main.cartolyticsCustomerID !== undefined
            && data.jobData !== undefined
            && data.jobData.cartolytics_customer_id !== undefined){
            return main.cartolyticsCustomerID == data.jobData.cartolytics_customer_id;
        }

        return true

    }

    return service;

}
