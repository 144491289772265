import * as angular from 'angular';

"use strict";

angular.module('ScanStationModule')
    .controller('TableOrderController', TableOrderController);

// Injection of each class
TableOrderController.$inject = ['$scope', 'ScopeAndLocal', 'scanStation'];

// Controller Constructor
function TableOrderController($scope, ScopeAndLocal, scanStation)  {

    // Creating the view model reference
    let vm = this;

    vm.orderModel = undefined;
    vm.scanStationFields = undefined;

    vm.$onInit = async function () {
        try {

            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            ScopeAndLocal.startVariable(vm, $scope, 'field');

            if (vm.scanStation != undefined && vm.scanStation.scanStationObj != undefined && vm.scanStation.scanStationObj.scanStationFields != undefined){
                vm.scanStationFields = vm.scanStation.scanStationObj.scanStationFields;
            }

        } catch (e) {
            console.log(e);
        }

    }


    /**
     * This will be responsible to re-order things.
     *
     * @param fieldToUpdate
     */
    vm.changeOrder = async (fieldToUpdate) => {
        try
        {
            let newOrderValue = parseInt(vm.orderModel);
            let updatedField = undefined;

            // we need to reorder the array first, using scanStationFields on its own is not enough because they are not ordered correctly
            // once we have rebuilt the array in the right order we can then continue with the ordering logic
            let fieldsUnordered = [...vm.scanStation.scanStationObj.scanStationFields];

            let numberOFieldsPlusOne = fieldsUnordered.length + 1;

            let cloneFields = [];
            let mainIndex = 1;

            for(let i=1;i<numberOFieldsPlusOne;i++){
                angular.forEach(fieldsUnordered, function(field) {
                    if(field.field_order == i) {
                        cloneFields.push(field);
                    }
                });
            }

            for (const [index, cloneField] of cloneFields.entries()) {
                if(cloneField.id == fieldToUpdate.id){
                    cloneField.field_order = newOrderValue;
                } else {
                    if(mainIndex == newOrderValue){
                        mainIndex++
                    }
                    cloneField.field_order = mainIndex++
                }
            }

            vm.scanStation.scanStationObj.scanStationFields = cloneFields;
            vm.orderModel = undefined;

            await scanStation.updateScanStationObjectOrder(vm.scanStation.scanStationObj);

            cloneFields = undefined;
            vm.showFieldRules = false;
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

}

export default TableOrderController;
