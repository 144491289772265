import * as angular from 'angular';

'use strict';

angular.module('LoginAttemptsModule')
    .service('LoginAttemptsModule', LoginAttemptsModule);

/**
 * This will be responsible for managing the of the user.
 * 
 * @constructor
 */
function LoginAttemptsModule() {
    let service = this;

}