import * as angular from 'angular';

"use strict";

angular.module('LoginAttemptsModule')
    .controller('LoginAttemptsController', LoginAttemptsController);

// Injection of each class
LoginAttemptsController.$inject = ['LoginAttemptsFactory', '$scope', 'ScopeAndLocal'];

// Controller Constructor
function LoginAttemptsController(LoginAttemptsFactory, $scope, ScopeAndLocal) {
    let vm = this;

    vm.$onInit = function () {
        try {
            ScopeAndLocal.startVariable(vm, $scope, 'main');

            let appSettingParsed = JSON.parse(localStorage.getItem('appSettings'));

            angular.forEach(appSettingParsed.data, function (settingEntry) {
                if (settingEntry.setting_key == 'max_login_attempts') {
                    vm.maxAttempts = parseInt(settingEntry.setting_value);
                }
                if (settingEntry.setting_key == 'login_attempts_timeout') {
                    vm.timeout = parseInt(settingEntry.setting_value);
                }
            })

        } catch (error) {
            console.log(error);
        }
    }

    vm.setLoginAttempts = async function (maxAttempts: number, timeout: number) {
        try {
            await LoginAttemptsFactory.setLoginAttempts(maxAttempts);
            await LoginAttemptsFactory.setAttemptTimeout(timeout);
            await LoginAttemptsFactory.updateInactivitySettingFromLocalStorage(maxAttempts, timeout);
            $scope.$apply();
            alert(`Max Login Attempts set to ${maxAttempts}, and lock out time to ${timeout}`);

        } catch (e) {
            console.log(e);
        }
    }

}

export default LoginAttemptsController;
