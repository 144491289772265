import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";
import WysiwygController from "./controllers/wysiwyg.controller";

"use strict";

class WysiwygComponent extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/field/components/wysiwyg';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        scanStation: '=',
        main: '=',
        data: '=',
        field: '=',
        row: '=',
        fc: '=',
    }

    // This will set what will be the controller of this component
    public controller : any = WysiwygController;

    // This will set an alias
    public controllerAs: string = 'w';
}

angular.module('FieldModule').component('wysiwyg', <StandardComponent> new WysiwygComponent);