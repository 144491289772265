import * as angular from 'angular';

"use strict";

angular.module('SoundModule')
       .controller('SoundController', SoundController);

SoundController.$inject = [];

// Controller Constructor
function SoundController() {

    // Creating the view model reference
    let vm = this;

    vm.$onInit = function () {
        try
        {

        } catch (e) {
            console.log(e);
        }
    }
}

export default SoundController;