import * as angular from 'angular';
import RolesAndPermissionsController from "./controllers/roles-and-permissions.controller";

"use strict";

let RolesAndPermissionsComponent =  {
    templateUrl: 'components/roles-and-permissions/template/roles-and-permissions.html',
    controller: RolesAndPermissionsController,
    controllerAs: 'rp',
    bindings: {
        scanStation: '<',
        data: '<',
		main: '='
    }
}

angular.module('RolesAndPermissionsModule')
       .component('rolesAndPermissions', RolesAndPermissionsComponent);
