import * as angular from 'angular';
import '../services/management.factory'
import '../services/customer.factory'

"use strict";

angular.module('ManagementModule')
    .controller('ManagementController', ManagementController);

ManagementController.$inject = ['$scope','ScopeAndLocal', 'ManagementFactory', 'CustomerFactory',
    'Manager', 'Auth' , '$state', 'Blocworx', 'LanguagesFactory'];

// Controller Constructor
function ManagementController($scope, ScopeAndLocal, ManagementFactory, CustomerFactory,
                              Manager, Auth, $state, Blocworx, LanguagesFactory) {

    // Creating the view model reference
    let vm = this;
    vm.blocworx = Blocworx;
    vm.show = false;
    vm.customerData = {};
    vm.selectedOwner = {};

    /**
     * Constructor class.
     */
    vm.$onInit = async function () {
        try
        {
            ScopeAndLocal.startVariable(vm, $scope, 'bootstrap');

            vm.auth = Auth;
            await vm.auth.loadSuperUser();

            vm.languages = LanguagesFactory;
            await vm.languages.bootstrapLanguages(vm.auth.loggedIn);

            if (vm.auth.tokenExists() && await vm.auth.isSuperUser() === true) {
                vm.superuser = true;
            }


        } catch ( e ) {
            console.log(e);
        }
    }

    /**
     * This will be responsible to load the customer details for the edit option
     * of this case.
     *
     */
    vm.getCartolyticsCustomerByID = async function () {
        try {
            vm.cartolyticsCustomer = {};

            if ($state.params != null && $state.params.id != null) {
                vm.cartolyticsCustomerID = $state.params.id;

                let customer = await CustomerFactory.getCartolyticsCustomer(vm.cartolyticsCustomerID);
                vm.cartolyticsCustomer = customer.data.data;

                await vm.loadSelectOptions();
            }

        } catch ( e ) {
            console.log(e);
        }

    }

    /**
     * This will be responsible to load the select variable
     * cartolyticsCustomers.
     */
    vm.loadSelectOptions = async function () {
        try
        {
            vm.cartolyticsCustomers = await CustomerFactory.getCartolyticsCustomersFiltered(vm.cartolyticsCustomerID);
            vm.cartolyticsCustomers = Blocworx.appendStart(vm.cartolyticsCustomers,[
                { 'header' : true, 'name': '(You must select to become a child)'},
                { 'id': null, 'name': 'No Owner'}
            ]);

        } catch ( e ) {
            console.log(e);
        }

    }

    /**
     * This will be responsible to use the Manager service
     * to load all cartolytics customers.
     */
    vm.getCartolyticsCustomers = async function () {
        try
        {
            let data = await Manager.getCartolyticsCustomers();
            vm.cartolyticsCustomers = data.data.data;
            vm.cartolyticsCustomersIdsToCustomer = await CustomerFactory.buildCartolyticsCustomerIdsToCustomer(vm.cartolyticsCustomers);
            vm.customerData = Blocworx.getData(data);

        } catch ( e ) {
            console.log(e);
        }
    }

    /**
     * This is the action of create a new cartolytics customer.
     */
    vm.createCartolyticsCustomer = async function () {
        try
        {
            let cartolyticsCustomerModel = await Manager.createCartolyticsCustomer(vm.cartolyticsCustomer);
            alert('Instance Created');

            // this will load the new data after insert
            await vm.getCartolyticsCustomers()
            vm.cartolyticsCustomer = {}
            await vm.loadSelectOptions();

            $scope.$apply()

        } catch ( e ) {
            console.log(e);
        }

    }

    /**
     * This will be responsible to update data from the manage-blocworx-customer
     * url.
     */
    vm.updateCartolyticsCustomer = async function () {
        try
        {
            await CustomerFactory.updateCustomerDetails(vm.cartolyticsCustomerID, vm.cartolyticsCustomer);
            alert('Customer Information Updated Successfully');
            $scope.$apply()

        } catch ( e ) {
            console.log(e);
        }
    }

    /**
     * This is the action of click to create a new customer.
     */
    vm.createNewCustomerButton = () => {
        vm.showForm();
    }

    /**
     * This will be checking if we can show the form element.
     */
    vm.canShowForm = () => {
        return vm.show;
    }

    /**
     * This will make the form show
     */
    vm.showForm = () => {
        vm.show = true;
    }

    /**
     * This action will be hiding the form.
     */
    vm.hideForm = () => {
        vm.show = false;
    }

    /**
     * This will hide any block that calls this function
     * the criteria of invalidation:
     * 1 - if you are not logged in
     * 2 - if you are not an superUser
     */
    vm.invalidateConditional = () => {

        // if isn't logged in, this page shouldn't show
        if (vm.auth == undefined || vm.auth.loggedIn == false) {
            return true;
        }

        // this will invalidate if isn't a super user
        if (vm.auth == undefined || vm.auth.superUser == false) {
            return true;
        }

        return false;

    }

    /**
     * This will be responsible to validate to show any block that call this function
     * you must know for this page to show you must:
     * 1 - be logged in
     * 2 - be an superuser
     */
    vm.validateConditional = () => {


        // if isn't logged in, this page shouldn't show
        if (vm.auth !== undefined && vm.auth.loggedIn != false && vm.auth.superUser != false) {
            return true;
        }

        return false;

    }

    /**
     * This will test if the object passed is empty.
     * @param object
     */
    vm.isEmpty = (object) => {
        return object == null || object == undefined ? true : false;
    }

}

export default ManagementController;