import * as angular from 'angular';
import * as $ from "jquery";

"use strict";

angular
    .module('FieldModule')
    .factory('AutoGenerateFieldFactory', AutoGenerateFieldFactory);


AutoGenerateFieldFactory.$inject = [];


/**
 * Object that will create a Autogenerate Factory, all methods of factory
 * will be available from outside.
 */
function AutoGenerateFieldFactory() {
    let factory = this;

    /**
     * Method responsible for setting the field parameters from edit
     * @param fieldParameters
     * @param intialFieldParams
     * @returns {*}
     */

    factory.setInitialFieldParametersFromEdit = function (fieldParameters, intialFieldParams) {
        angular.forEach(fieldParameters, function(fieldParam) {
            if(fieldParam.parameter_type == 'initialFieldParameter') {
                intialFieldParams[fieldParam.scan_station_field_parameter] = fieldParam.parameter_value;
            }
        });

        return intialFieldParams;
    }

    return factory;
}

