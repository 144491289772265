import * as angular from 'angular';

"use strict";

angular.module('ResetForgotPasswordModule')
       .controller('ForgotPasswordSwitchController', ForgotPasswordController);

ForgotPasswordController.$inject = ['$scope', 'User', 'ForgotPasswordFactory', '$state'];

// Controller Constructor
function ForgotPasswordController($scope, User, ForgotPasswordFactory, $state) {

    // Creating the view model reference
    let vm = this;
    vm.main = '';
    vm.data = '';
    vm.forgotPasswordError = false;
    vm.waitingForResponse = false;

    vm.$onInit = function () {
        try {
            $scope.main = vm.main;
            $scope.data = vm.data;

        } catch (e) {
            console.log(e);
        }
    }

    vm.sendResetPasswordLink = async function() {

        vm.waitingForResponse = true;

        let validate = await vm.validate(['validateEmail']);

        if(validate[0]==true) {
            try {
                await ForgotPasswordFactory.sendResetPasswordLink(vm.emailAddress);
                vm.waitingForResponse = false;
                vm.forgotPasswordSuccess = "Thank you, an email has been sent to this email address with a Reset Password link."
                $scope.$apply();
            } catch {
                vm.passwordResetError = 'There was an issue processing this request. Please try again later or contact support@blocworx.com';
                vm.waitingForResponse = false;
                $scope.$apply();
            }
        } else {
            vm.passwordResetError = validate[1];
            vm.waitingForResponse = false;
            $scope.$apply();
        }

    }

    vm.resetPassword = async function() {
        let validate = await vm.validate(['validateEmail','passwordsMatch','isComplexPassword']);


        if(validate[0]==true) {
            try {
                console.log(validate);
                await ForgotPasswordFactory.resetPassword(vm.emailAddress, $state.params.token, vm.password);
                vm.waitingForResponse = false;
                vm.resetPasswordSuccess = true;
                vm.resetPasswordSuccessMessage = "Thank you, your password is now updated. Please ";
                $scope.$apply();
            } catch (e) {
                console.log(e);
                vm.passwordResetError = 'There was an issue processing this request. Please try again later or contact support@blocworx.com';
                vm.waitingForResponse = false;
                $scope.$apply();
            }
        } else {
            vm.passwordResetError = validate[1];
            vm.waitingForResponse = false;
            $scope.$apply();
        }
    }

    vm.validate = async function(criteria) {
        let validated = true;
        let response = '';

        angular.forEach(criteria, function(value) {

            if(value == 'validateEmail') {
                let checkEmail = User.validateEmail(vm.emailAddress);
                if(checkEmail == false) {
                    validated = false;
                    response = 'This is not a valid email address. Please try again.';
                }
            }

            if(value == 'passwordsMatch') {
                if(vm.password != vm.confirmPassword) {
                    validated = false;
                    response = 'The passwords do not match, please try again.';
                }
            }

            if(value == 'isComplexPassword') {
                let checkPassword = User.validatePassword(vm.password);
                if(checkPassword == false) {
                    validated = false;
                    response = 'Password must contain at least 1 uppercase, 1 lowercase, 1 digit and 2 special characters from [£$&+,:;=?@#|\'<>.^*()%!-] and be at least 8 characters long.';
                }
            }

        });

        return [validated, response];
    }


}

export default ForgotPasswordController;