import * as angular from 'angular';

"use strict";

angular.module('TwoFactorAuthenticationModule')
       .service('TwoFactorAuthentication', TwoFactorAuthentication);

TwoFactorAuthentication.$injector = ['$http', 'Configuration'];

function TwoFactorAuthentication($http, Configuration) {

    const service = this;
    const apiUrl = Configuration.getApiUrl();

    /**
     * This will be checking if exist a user details into the database
     * by searching though the users table and getting if
     * the email (that we use as username) exists by a search for it
     * into the users table.
     *
     * @param email
     */
    service.existUser = async (email: string) => {

        let existUser = false;
        let apiBase = apiUrl + 'exist-user';
        let config = {
            params: {
                email
            }
        }
        const response = await $http.get(apiBase, config);
        if (response !== undefined && response.data !== undefined) {
            existUser = response.data.existUser;
        }

        return existUser;
    }

    service.isRequired = async function (email: string): Promise<boolean> {

        let apiBase = apiUrl + 'is-2fa-required';
        let config = {
            params: {
                email
            }
        }

        // TODO: Add error handling
        const response = await $http.get(apiBase, config);

        return response.data.is2FARequired;
    }

    service.validateCredentials = async function (email: string, password: string): Promise<boolean> {

        let apiBase = apiUrl + 'validate-credentials-for-2fa';
        let config = {
            email,
            password
        }

        // TODO: Add error handling
        const response = await $http.post(apiBase, config);

        return response.data.isValid;
    }

    service.verifyCode = async function (code: string): Promise<boolean> {

        let apiBase = apiUrl + 'verify-code-for-2fa';
        let config = {
            two_factor_code: code
        }

        const response = await $http.post(apiBase, config);

        return response.data.success;
    }

    service.resendCode = async function (email: string): Promise<boolean> {

        let apiBase = apiUrl + 'resend-code-for-2fa';
        let config = {
            email
        }

        const response = await $http.post(apiBase, config);

        return response.data.success;
    }


    return service;

}

export default TwoFactorAuthentication;