import * as angular from 'angular';

'use strict';

angular.module('ManageInactivityModule')
    .service('ManageInactivityModule', ManageInactivityModule);

/**
 * This will be responsible for managing the inactivity of the user.
 * 
 * @constructor
 */
function ManageInactivityModule() {
    let service = this;

}