import * as angular from 'angular';

"use strict";

angular.module('TemplateModule')
       .factory('TemplateFactory', TemplateFactory)
;


TemplateFactory.$inject = [];

/**
 * Object that will create a Page Factory, all methods of factory
 * will be available from outside.
 *
 * @param $http
 * @constructor
 */
function TemplateFactory() {

    let factory = this;

    return factory;

}

export default TemplateFactory;
