import * as angular from 'angular';
import jQuery from "jquery";

declare const window: any;

"use strict";

angular.module('FieldModule')
    .controller('CountController', CountController);

// Injection of each class
CountController.$inject = ['$rootScope', '$scope', 'ScopeAndLocal', '$window', 'CountDataFactory'];

// Controller Constructor
function CountController($rootScope, $scope, ScopeAndLocal, $window, CountDataFactory) {

    let vm = this;
    vm.countsOfFields = [];

    vm.$onInit = async function () {
        try {
            ScopeAndLocal.startVariable(vm, $scope, 'action');
            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            ScopeAndLocal.startVariable(vm, $scope, 'data');
            ScopeAndLocal.startVariable(vm, $scope, 'field');

        } catch (e) {
            console.log(e);
        }
    }

    /*
    Method for counting all the data by a particular field Slug
    @param fieldSlug
     */

    vm.getCountsOfFieldSlug = async function(fieldSlug) {

        if(vm.scanStation.scanStationFieldSlugsToField[fieldSlug].fieldIsCountable == 1 || vm.scanStation.scanStationFieldSlugsToField[fieldSlug].filterableField == 1) {
            vm.countsOfFields[fieldSlug] = await CountDataFactory.getCountsOfFieldSlug(fieldSlug, vm.scanStation.id);
        } else {
            vm.countsOfFields[fieldSlug] = {
                'No Counting Available for this field' : ''
            };
        }

        $scope.$apply();
    }

}

export default CountController;