import * as angular from 'angular';

"use strict";

angular.module('SoundModule')
       .service('sound', soundService);

soundService.$inject = [];

/**
 * Directive that will build the show more.
 *
 * @returns
 * @constructor
 */
function soundService() {

    let service = this;

    /**
     * This will be looping though the scan station fields, it will check if the
     * soundFieldToCheck exist and contain value = 1 (meaning it is turned it on), otherwise
     * it will be returning false.
     *
     * @param soundFieldToCheck
     */
    service.shouldPlaySound = (scanStationObj, soundFieldToCheck) => {

        let play = false;

        angular.forEach(scanStationObj.scanStationFields,function(field, key) {
            if(field[soundFieldToCheck] !== undefined && field[soundFieldToCheck] === 1 ){
                play = true
            }
        });
        return play;
    }

    /**
     * This method will play an audio path file.
     * @param audioPath path for the audio foder, starts under
     * application/resources
     * after webpack you must check if they are at application/build/resources
     */
    service.playSound = async function (audioPath) {
        try
        {
            let snd = new Audio(audioPath);
            await snd.play();

        } catch (e) {
            console.log(e);
        }
    };

    service.playErrorSound = async function (sound) {
        await service.playSound("/resources/error.mp3");
    };

    service.playWarningSound = async function (sound) {
        await service.playSound("/resources/warning-sound.mp3");
    };

    service.playScanSound = async function (sound) {
        await service.playSound("/resources/scan.mp3");
    };


    return service;

}