import * as angular from 'angular';

"use strict";

angular.module('SidebarPagesModule')
       .factory('SidebarPagesFactory', SidebarPagesFactory)
       .config(SidebarPagesConfiguration);

/**
 * Object that will set aHrefSanitizationWhitelist for requests
 * in this factory.
 *
 * @param $compileProvider
 * @constructor
 */
function SidebarPagesConfiguration($compileProvider) {
    $compileProvider.aHrefSanitizationWhitelist(
        /^\s*(https?|ftp|mailto|tel|file|blob):/);
}

SidebarPagesFactory.$inject = ['$http', 'Configuration'];

/**
 * Object that will create a SidebarPages Factory, all methods of factory
 * will be available from outside.
 *
 * @param $http
 * @returns {SidebarPagesFactory}
 * @constructor
 */
function SidebarPagesFactory($http, Configuration) {
    let factory = this;

    /**
     * This will be getting the sidebar pages.
     */
    factory.getSidebarPages = async function () {

        let sidebarPages = [];
        let apiBase = Configuration.getApiUrl() + 'get-sidebar-pages';
        let data = {};

        let response = await $http.get(apiBase, data);
        if(response.data != undefined && response.data.data != undefined){
            sidebarPages = response.data.data;
        }

        return sidebarPages;
    }

    return factory;

}
