import * as angular from 'angular';

"use strict";
angular.module('FieldModule')
       .controller('PieChartController', PieChartController);

// Injection of each class
PieChartController.$inject = ['PieChartField', 'Configuration', '$scope'];

// Controller Constructor
function PieChartController(PieChartField, Configuration, $scope) {

    let vm = this;

    vm.$onInit = async function () {
        try
        {
            vm.version = Configuration.getVersion();
            vm.field = PieChartField;

        } catch (e) {
            console.log(e);
        }
    }

}
