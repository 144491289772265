import * as angular from 'angular';
import $ from 'jquery';

"use strict";

angular.module('PermissionModule')
       .controller('PermissionScanStationController', PermissionScanStationController);

PermissionScanStationController.$inject = ['$scope', 'ScopeAndLocal', 'Permission', 'LinkFieldFactory'];

// Controller Constructor
function PermissionScanStationController($scope, ScopeAndLocal, Permission, LinkFieldFactory) {

    // Creating the view model reference
    let vm = this;

    vm.$onInit = function () {
        try
        {
            // This is starting each vm and $scope variables
            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            ScopeAndLocal.startVariable(vm, $scope, 'rowKey');
            ScopeAndLocal.startVariable(vm, $scope, 'row');
            ScopeAndLocal.startVariable(vm, $scope, 'rowId');
            ScopeAndLocal.startVariable(vm, $scope, 'element');
            ScopeAndLocal.startVariable(vm, $scope, 'data');
            ScopeAndLocal.startVariable(vm, $scope, 'main');

            vm.linkFactory = LinkFieldFactory


        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Main validation for the user be able to edit or not the data.
     */
    vm.canEdit = () => {
        return Permission.canEditScanStation(vm.main, vm.scanStation);
    }

    /**
     * This will get all the data from the scan station edit button.
     * @param row
     * @param rowKey
     */
    vm.editDataButtonAction = (row, rowKey) => {

        vm.scanStation.scanStationObj.showForm  = true;
        vm.data.showForm=true;
        vm.scanStation.editDataField = !vm.scanStation.editDataField;
        vm.scanStation.setFieldsEditEntry(row, rowKey);
        vm.scanStation.showMoreSingleDataBox = false;
        vm.scanStation.scanStationObj.isInEditMode = true;
        vm.scanStation.editCheckFields(row);
    }

    /**
     * This is the main function that will check if this user can or can not
     * see the show more button.
     *
     */
    vm.showMoreData = () => {

        if (vm.scanStation.scanStationObj.stationDetails.hide_show_more){
            return false;
        }
        return true;
    }

    /**
     * This will be the action of the anchor action for
     * the show more button.
     *
     * @param row
     * @param rowKey
     */
    vm.showMoreClick = (row, rowKey, $event) => {

        // after click to show more, if we have any data to load we can use this property
        vm.scanStation.scanStationObj.isInViewMode = false;
        vm.scanStation.cleanCheckboxFromStation(vm.scanStation.scanStationObj);
        vm.scanStation.scanStationObj.showForm = true;
        vm.scanStation.scanStationObj.isInEditMode = false;
        vm.scanStation.showMoreSingleDataBox = true;
        vm.scanStation.singleEntry = row;
        vm.scanStation.rowKey = rowKey;
        vm.scanStation.setFieldsEditEntry(row, rowKey);
        vm.scanStation.editCheckFields(row);
        vm.scanStation.triggerChartUpdates(vm.scanStation.scanStationObj.scanStationFields);


        let canTriggerClass = "can-trigger-edit"

        // this part of the code is to search for the main tbody and remove the reference of the can-trigger-edit
        $('.form-results-section tr').removeClass('can-trigger-edit');

        // the second part is more to add the class, so we can only trigger one each time
        let td = $($event.target).parent();
        let tr = $(td).parent();

        if($(tr).hasClass('flex-table')){
            $(tr).addClass(canTriggerClass);
        }

    }

    /**
     * This will check if we have the option on scan station object
     * station details show edit links as default.
     */
    vm.showEditLinkByDefault = () => {
        if(vm.scanStation.scanStationObj.stationDetails.show_edit_links == 1){
            vm.scanStation.editLinkEnabled = 1;
        }
    }

    /**
     * This will trigger the action of showing the edit fields on the
     * data section of the scan station.
     */
    vm.editLinkClickAction = () => {
        vm.scanStation.editLinkEnabled = !vm.scanStation.editLinkEnabled;
        vm.scanStation.openFiltering = !vm.scanStation.openFiltering;
    }

    /**
     * This will be the main action of open the filter action
     * on the scan station checkbox "Show Edit Links and Data Filtering"
     */
    vm.openFilteringClickAction = () => {
        if(vm.scanStation.openFiltering !== false){
            vm.scanStation.openFiltering = true;
        } else {
            vm.scanStation.openFiltering = false;
        }
    }

    /**
     * This is the action when we trigger the search button on a
     * scan station data page.
     *
     * @param $event
     */
    vm.searchButtonAction = ($event) => {
        vm.scanStation.filterData=true;
        vm.scanStation.currentPage=1;
        vm.scanStation.dataFilteringData.currentPage = 1;
        vm.scanStation.triggerGetDataFiltering($event);
    }

}

export default PermissionScanStationController;