import * as angular from 'angular';
import * as $ from "jquery";

"use strict";

angular
    .module('FieldModule')
    .factory('CrossBlocDataUpdatesFieldFactory', CrossBlocDataUpdatesFieldFactory);


CrossBlocDataUpdatesFieldFactory.$inject = ['fieldService', 'Configuration', '$http'];


/**
 * Object that will create a Plain Data Factory, all methods of factory
 * will be available from outside.
 */
function CrossBlocDataUpdatesFieldFactory(fieldService, Configuration, $http) {
    let factory = this;

    /**
     * Method responsible for setting the field parameters from edit
     * @param fieldParameters
     * @returns {*}
     */


    /**
     * Method responsible for building and editing the local matching fields Array for updating checking against
     * @param localFieldMatchingFieldPairsArray
     * @param action
     * @param localField
     * @param remoteField
     * @param index
     * @returns {*}
     */
    factory.addOrRemoveMatchingFieldPairs = function (localFieldMatchingFieldPairsArray, action, localField, remoteField, index = null) {

        if((localField == '' || typeof localField == 'undefined') && action == 'add') {
            alert('Please Select a local Field');
            return localFieldMatchingFieldPairsArray;
        }

        if((remoteField == '' || typeof remoteField == 'undefined') && action == 'add') {
            alert('Please Select a remote field it must match');
            return localFieldMatchingFieldPairsArray;
        }

        if(typeof localFieldMatchingFieldPairsArray == 'undefined') {
            localFieldMatchingFieldPairsArray = {};
        }

        if(action == 'add') {
            localField = JSON.parse(localField);
            remoteField = JSON.parse(remoteField);
            console.log(localFieldMatchingFieldPairsArray);
            localFieldMatchingFieldPairsArray[Object.keys(localFieldMatchingFieldPairsArray).length] = {
                'localFieldId' : localField.id,
                'remoteFieldId' : remoteField.id,
                'localFieldName' : localField.field_name,
                'remoteFieldName' : remoteField.field_name
            };

        } else {
            delete localFieldMatchingFieldPairsArray[index];
            // build new object with fresh keys to keep it in sync
            var fieldListForLoop = localFieldMatchingFieldPairsArray;
            localFieldMatchingFieldPairsArray = {};
            var i = 0;
            angular.forEach(fieldListForLoop, function(value) {
                localFieldMatchingFieldPairsArray[i] = value;
                i++;
            });
        }

        return localFieldMatchingFieldPairsArray;

    }

    factory.triggerActions = function (fieldIds, dataToAdd) {
        let apiBase = Configuration.getApiUrl() + 'trigger-cross-bloc-data-actions';
        let data = {
            fieldIds: fieldIds,
            dataToAdd: dataToAdd
        };
        return $http.post(apiBase, data);
    }

    return factory;
}

