import * as angular from 'angular';
import ValidatorInterface from "components/field/components/fileBuilder/interfaces/validator.interface";

'use strict';

angular.module('ValidatorsModule')
       .service('FormValidator', FormValidatorObject);

/**
 * This will be responsible to implement filters on the
 * application.
 *
 * @constructor
 */
function FormValidatorObject() {

    const service = this;
    let validators : ValidatorInterface[] = [];

    service.validated = true;
    service.error;

    /**
     * Method that will be adding a validator to the scanStationForm.
     *
     * @param newValidator
     */
    service.addValidator = async (newValidator : ValidatorInterface ) => {

        let exist = false;

        // loop though the array to check if exist
        validators.forEach(validator => {
            if(validator.name == newValidator.name){
                exist = true;
            }
        });

        // we will be adding only if does not exist
        if(!exist){
            validators = [...validators, newValidator];
        }
    }

    /**
     * Method that will get all registered validators from the previous function
     * addValidator and it will run all the validate methods.
     * In a case of one of the functions we have an exception we will
     * be capturing those and throwing to stop the execution, and the message
     * must be the message to pass to the frontend.
     *
     * @param dataToValidate
     */
    service.validate = async (dataToValidate) => {
        try
        {
            for (let index = 0; index < validators.length; index++) {
                await validators[index].method(dataToValidate);
            }

        } catch ( error ) {
            throw error;
        }
    }

    service.$get = function() {
        return service;
    };

}
