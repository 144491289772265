import * as angular from 'angular';

"use strict";

angular.module('PermissionModule')
       .service('Permission', PermissionService);

PermissionService.$inject = [];

/**
 * Directive that will build the show more.
 *
 * @returns
 * @constructor
 */
function PermissionService() {

    let service = this;

    service.canEditScanStation = function (main, scanStation) {
        
        let canEdit = false;

        // Main rule that includes a user has the permission can-edit-data-in-form or it is an admin
        if (main.userRoles.includes('can-edit-data-in-form') || main.userRoles.includes('admin-front-end') || main.userRoles.includes('admin')){
            canEdit = true;
        }

        // extra rule when a scanStation contain the config permission_edit_data_all
        if(scanStation.scanStationObj !== undefined && scanStation.scanStationObj.stationDetails !== undefined
            && scanStation.scanStationObj.stationDetails.permission_edit_data_all !== undefined) {
            if (scanStation.scanStationObj.stationDetails.permission_edit_data_all != 0){
                canEdit = true;
            }
        }

        return canEdit;
        
    }

    service.$get = function () {

        return {
            canEditScanStation: service.canEditScanStation,
        }

    };

}