import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";
import MailAlertController from "./controllers/mail-alert.controller";

"use strict";

class MailAlertComponent extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/field/components/mailAlert';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        action: '@',
        scanStation: '=',
        fc: '=',
        field: '=',
        row: '=',
        editScanStation: '@',
        data: '='
    }

    // This will set what will be the controller of this component
    public controller : any = MailAlertController;

    // This will set an alias
    public controllerAs: string = 'mailAlert';
}

angular.module('FieldModule').component('mailAlert', <StandardComponent> new MailAlertComponent);