import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";
import ManagementController from "components/management/controllers/management.controller";

"use strict";

export default class ManagementBlocworx extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/management';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        bootstrap: '<'
    }

    // This will set what will be the controller of this component
    public controller : any = ManagementController;

    // This will set an alias
    public controllerAs: string = 'management';
}

angular.module('ManagementModule').component('managementBlocworx', <StandardComponent> new ManagementBlocworx);

