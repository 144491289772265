class StandardComponent implements ng.IComponentOptions {

    public element;
    public attrs;
    public moduleFolder;

    // Component Options

    public debug = false;

    templateUrl = ($element, $attrs) => {

        // this will check if we are on scan station edit case
        let edit = $attrs.editScanStation === undefined ? false : true;

        // main rule will be create on the root template folder or inside of create-edit
        let htmlFile = edit ?  `create-edit/${$attrs.action}.html` : `${$attrs.action}.html`;

        let templateUrl = `${this.moduleFolder}/template/${htmlFile}`;

        if (this.debug == true){
            console.log(`Template: ${templateUrl}`);
        }

        return templateUrl
    }

}

export default StandardComponent;