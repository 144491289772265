import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
    .factory('CountDataFactory', CountDataFactory)
    .config(CountDataConfiguration);

/**
 * Object that will set aHrefSanitizationWhitelist for requests
 * in this factory.
 *
 * @param $compileProvider
 * @constructor
 */
function CountDataConfiguration($compileProvider) {
    $compileProvider.aHrefSanitizationWhitelist(
        /^\s*(https?|ftp|mailto|tel|file|blob):/);
}

CountDataFactory.$inject = ['$http', 'Configuration'];

/**
 * Object that will create a CountData Factory, all methods of factory
 * will be available from outside.
 *
 * @param $http
 * @param Configuration
 * @constructor
 */
function CountDataFactory($http, Configuration) {

    let factory = this;

    factory.getCountsOfFieldSlug = async function (fieldSlug, scanStationId) {

        let apiBase = Configuration.getApiUrl()  + 'get-counts-of-field-slug';

        let config = {
            params: {
                fieldSlug: fieldSlug,
                scanStationId: scanStationId
            }
        };

        let response = await $http.get(apiBase, config);

        return response.data;

    }

    return factory;

}
