import * as angular from 'angular';
import * as $ from "jquery";

"use strict";

angular
    .module('FieldModule')
    .factory('SubFormFieldFactory', SubFormFieldFactory);


SubFormFieldFactory.$inject = ['fieldService'];


/**
 * Object that will create a Plain Data Factory, all methods of factory
 * will be available from outside.
 */
function SubFormFieldFactory(fieldService) {
    let factory = this;

    /**
     * Method responsible for building and editing the sub form child-parent Array
     * @param subFormParentChildFieldsArray
     * @param action
     * @param parentField
     * @param childField
     * @param index
     * @returns {*}
     */

    factory.addOrRemoveSubFormParentChildFields = function (subFormParentChildFieldsArray, action, parentField, childField, index = null) {

        if((parentField == '' || typeof parentField == 'undefined') && action == 'add') {
            alert('Please Select a Parent Field');
            return subFormParentChildFieldsArray;
        }

        if((childField == '' || typeof childField == 'undefined') && action == 'add') {
            alert('Please Select a remote field it must match');
            return subFormParentChildFieldsArray;
        }

        if(typeof subFormParentChildFieldsArray == 'undefined') {
            subFormParentChildFieldsArray = {};
        }

        if(action == 'add') {
            parentField = JSON.parse(parentField);
            childField = JSON.parse(childField);
            subFormParentChildFieldsArray[Object.keys(subFormParentChildFieldsArray).length] = {
                'parentFieldId' : parentField.id,
                'childFieldId' : childField.id,
                'parentFieldName' : parentField.field_name,
                'childFieldName' : childField.field_name
            };

        } else {
            delete subFormParentChildFieldsArray[index];
            // build new object with fresh keys to keep it in sync
            var fieldListForLoop = subFormParentChildFieldsArray;
            subFormParentChildFieldsArray = {};
            var i = 0;
            angular.forEach(fieldListForLoop, function(value) {
                subFormParentChildFieldsArray[i] = value;
                i++;
            });
        }


        return subFormParentChildFieldsArray;

    }

    /**
     * Method responsible for building and editing the sub form child-parent array where data is fed from the child to the parent
     * @param subFormParentChildFieldsArray
     * @param action
     * @param childField
     * @param parentField
     * @param index
     * @returns {*}
     */

    factory.subFormSendChildToParentParentSelectedChildField = function (subFormSendChildDataToParentFieldsArray, action, childField, parentField, index = null) {

        if((parentField == '' || typeof parentField == 'undefined') && action == 'add') {
            alert('Please Select a Parent Field');
            return subFormSendChildDataToParentFieldsArray;
        }

        if((childField == '' || typeof childField == 'undefined') && action == 'add') {
            alert('Please Select a remote field it must match');
            return subFormSendChildDataToParentFieldsArray;
        }

        if(typeof subFormSendChildDataToParentFieldsArray == 'undefined') {
            subFormSendChildDataToParentFieldsArray = {};
        }

        if(action == 'add') {
            parentField = JSON.parse(parentField);
            childField = JSON.parse(childField);
            subFormSendChildDataToParentFieldsArray[Object.keys(subFormSendChildDataToParentFieldsArray).length] = {
                'parentFieldId' : parentField.id,
                'childFieldId' : childField.id,
                'parentFieldName' : parentField.field_name,
                'childFieldName' : childField.field_name
            };

        } else {
            delete subFormSendChildDataToParentFieldsArray[index];
            // build new object with fresh keys to keep it in sync
            var fieldListForLoop = subFormSendChildDataToParentFieldsArray;
            subFormSendChildDataToParentFieldsArray = {};
            var i = 0;
            angular.forEach(fieldListForLoop, function(value) {
                subFormSendChildDataToParentFieldsArray[i] = value;
                i++;
            });
        }


        return subFormSendChildDataToParentFieldsArray;

    }

    /**
     * Method responsible for setting the field parameters from edit
     * @param fieldParameters
     * @returns {*}
     */

    factory.setInitialFieldParametersFromEdit = function (fieldParameters) {

        var parametersToReturn = {};
        var parametersAvailable = [
            'formceptionTwoStationAndJob',
            'subFormParentChildFields',
            'subFormParentChildFieldsArray',
            'subFormSendChildDataToParentFieldsArray',
            'subFormSendChildDataToParentFields',
            'triggerSubBlocOption',
            'formCeptionPopUpOrEmbed',
            'clickShowMoreLastEntry',
            'closeTheSubBlocOnly',
            'closeTheSubBlocAndSubmitParentForm',
            'hideCloseButton',
            'closeSubBlocAndReOpenAnotherSubBlocAfterSubmit',
            'closeSubBlocAndReOpenAnotherSubBlocUsingSeperateButton',
            'closeSubBlocAndReOpenFieldId',
            'seperateButtonText',
            'compactView'

        ];

        if(Array.isArray(fieldParameters)){

            // adding the scan station field id in case to need to load after some extra things
            parametersToReturn['scan_station_field_id'] = fieldParameters[0]['scan_station_field_id'];

            angular.forEach(fieldParameters, function(fieldParam) {

                if(parametersAvailable.includes(fieldParam.scan_station_field_parameter)) {
                    try {
                        parametersToReturn[fieldParam.scan_station_field_parameter] = JSON.parse(fieldParam.parameter_value);
                    } catch(e) {
                        parametersToReturn[fieldParam.scan_station_field_parameter] = fieldParam.parameter_value;
                    }
                }
            });
        }

        return parametersToReturn;
    }

    return factory;
}

