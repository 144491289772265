import * as angular from 'angular';
import "components/blocworx/services/blocworx.service.ts"


"use strict";

angular.module('BlocworxModule')
       .factory('CustomerFactory', FormFactory);

FormFactory.$inject = ['$http', '$rootScope', 'Configuration', 'Blocworx'];

/**
 *
 * @constructor
 */
function FormFactory($http, $rootScope, Configuration, Blocworx) {

    let factory = this;
    let apiUrl = Configuration.getApiUrl();

    /**
     * This will be responsible to update a customer detail on the
     * manage-blocworx-customer block
     * @param customerID
     * @param customerDetails
     */
    factory.updateCustomerDetails = (customerID, customerDetails) =>  {

        let apiBase = apiUrl + 'update-blocworx-customer';
        let data = {
            customerID: customerID,
            customerDetails: customerDetails
        };

        return $http.post(apiBase, data);
    }

    /**
     * This will be responsible to load a cartolytics customer by ID.     *
     * @param customerID
     * @param customerDetails
     */
    factory.getCartolyticsCustomer = (id) =>  {

        let apiBase = apiUrl + 'get-cartolytics-customer';
        let config = {
            params: {
                id: id
            }

        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be responsible to load all cartolytics customer ID
     * and remove your own, as you can't set yourself as father.
     *
     * @param customerID
     * @param customerDetails
     */
    factory.getCartolyticsCustomersFiltered = async (id) =>  {

        let apiBase = apiUrl + 'get-cartolytics-customers-filtered';
        let config = {
            params: {
                id: id
            }

        };
        let data = await $http.get(apiBase, config);

        return Blocworx.getData(data);
    }

    /**
     * This is for creating an easier list of customers by their ID
     * @param cartolyticsCustomers
     */

    factory.buildCartolyticsCustomerIdsToCustomer = (cartolyticsCustomers) => {

        let cartolyticsCustomersIdsToCustomer = [];
        angular.forEach(cartolyticsCustomers, function(customer) {
            cartolyticsCustomersIdsToCustomer[customer.id] = customer;
        });

        return cartolyticsCustomersIdsToCustomer;
    }


    return factory;
}

export default FormFactory;