import * as angular from 'angular';
import FieldController from "./field.controller";

"use strict";
angular.module('FieldModule')
       .controller('CrossBlocDataUpdatesController', CrossBlocDataUpdatesController);

// Injection of each class
CrossBlocDataUpdatesController.$inject = [ 'Configuration', '$scope', 'CrossBlocDataUpdatesFieldFactory'];

// Controller Constructor
function CrossBlocDataUpdatesController( Configuration, $scope, CrossBlocDataUpdatesFieldFactory) {

    let vm = this;

    vm.$onInit = async function () {
        try
        {
            vm.version = Configuration.getVersion();
            $scope.scanStation = vm.scanStation;
            $scope.field = vm.field;

        } catch (e) {
            console.log(e);
        }
    }

    vm.triggerAction = async function(fieldId, dataToAdd) {
        let actionResponse = await CrossBlocDataUpdatesFieldFactory.triggerActions([fieldId], dataToAdd);
        let field = vm.scanStation.scanStationFieldIdsToField[fieldId];
        vm.scanStation.scanStationObj.dataToAdd[field.field_slug] = actionResponse.data.data['fieldId_'+fieldId];
        if(field.whenActionHappens == 'on-button-click') {
            await vm.scanStation.getRules('no-event', fieldId, field.special_field_key, vm.scanStation.scanStationObj.dataToAdd[field.field_slug], field.domIndex, field.field_slug);
            await vm.scanStation.updateDropDownsRadiosAutoCompletesForAllFields();
            await vm.scanStation.triggerAllResultsBoxes();
            $scope.$apply();
        }
        $scope.$apply();
    }

}

export default CrossBlocDataUpdatesController;
