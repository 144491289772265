import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";
import QrController from "./controllers/qrCode.controller";

"use strict";

class QrComponent extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/field/components/qrCode';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        action: '@',
        scanStation: '=',
        fc: '=',
        field: '=',
        row: '=',
        editScanStation: '@'
    }

    // This will set what will be the controller of this component
    public controller : any = QrController;

    // This will set an alias
    public controllerAs: string = 'qr';
}

angular.module('FieldModule').component('qrCode', <StandardComponent> new QrComponent);