import * as angular from 'angular';
import LoginAttemptsController from './controllers/login-attempts.controller';
import StandardComponent from 'components/standards/components/StandardComponent';

"use strict";

class LoginAttemptsComponent extends StandardComponent {

    // This will enable the debug mode for this component
    public debug = false;
    public moduleFolder = 'components/login-attempts';

    /**
     * Please make sure that each function contains correct
     * documentation.
     */

    public bindings = {
        main: '<',
    }

    // This will set what will be the controller of this component
    public controller: any = LoginAttemptsController;

    // This will set an alias
    public controllerAs: string = 'la';
}

angular.module('LoginAttemptsModule').component('loginAttempts', <StandardComponent> new LoginAttemptsComponent);