import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
       .service('mindeeField', mindeeField);

/**
 * Service constructor.
 * @constructor
 */
function mindeeField() {
    let service = this;


    /**
     * Returns the initial Parameters for this field, to be distinguished from other parameters the field may have in the future after creating it
     */
    service.getInitialParams = async function () {
        try
        {
            let initialParams = {
                'apiUrl': {
                    'validation' : {
                        'required': true,
                        'validationResponse': 'Please provide an API Url',
                        'requiredType': 'stringMustNotBeEmpty'
                    }
                },
                'apiKey': {
                    'validation' : {
                        'required': true,
                        'validationResponse': 'Please provide an API Key',
                        'requiredType': 'stringMustNotBeEmpty'
                    }
                },
                'bulkUploadDataGenerator': {
                    'validation' : {
                        'required': false
                    }
                },
                'mindeeAPIType': {
                    'validation' : {
                        'required': false
                    }
                },
                'mindeeMappedFieldsArray': {
                    'validation': {
                        'required': true,
                        'validationResponse': 'Please map at least one field with a Mindee key',
                        'requiredType': 'listMustHaveOne'
                    }
                },
                'mindeeMappedFields': {
                    'validation': {
                        'required': true,
                        'validationResponse': 'Please map at least one field with a Mindee key',
                        'requiredType': 'listMustHaveOne'
                    }
                },
                'externalButtonToChooseBulkOrNot': {
                    'validation' : {
                        'required': false
                    }
                },
                'externalButtonValueForBulk': {
                    'validation' : {
                        'required': false
                    }
                },
                'externalButtonValueForSingle': {
                    'validation' : {
                        'required': false
                    }
                },

            }

            return initialParams;

        } catch (e) {
            console.log(e);
        }
    }

}