import * as angular from 'angular';
import * as $ from "jquery";

"use strict";

angular
    .module('FieldModule')
    .factory('MultiButtonFactory', MultiButtonFactory);


MultiButtonFactory.$inject = [];


/**
 * Object that will create a Multi Button Factory, all methods of factory
 * will be available from outside.
 */
function MultiButtonFactory() {
    let factory = this;

    /**
     * Method responsible for building and editing the multi button field
     * @param multiButtonList
     * @param action
     * @param buttonName
     * @param index
     * @returns {*}
     */
    factory.addOrRemoveButtonForMultiButton = function (multiButtonList, action, buttonName, index = null) {
        if((buttonName == '' || typeof buttonName == 'undefined') && action == 'add') {
            alert('Please Enter a button value');
            return multiButtonList;
        }
        if(typeof multiButtonList == 'undefined') {
            multiButtonList = {};
        }

        if(action == 'add') {

            multiButtonList[Object.keys(multiButtonList).length] = buttonName;

        } else {
            delete multiButtonList[index];
            // build new object with fresh keys to keep it in sync
            var multiButtonListForLoop = multiButtonList;
            multiButtonList = {};
            var i = 0;
            angular.forEach(multiButtonListForLoop, function(value) {
                multiButtonList[i] = value;
                i++;
            });
        }

        $('#new-multibutton-button-name').trigger('focus');



        return multiButtonList;

    }

    /**
     * Method responsible for setting the field parameters from edit
     * @param fieldParameters
     * @returns {*}
     */

    factory.setInitialFieldParametersFromEdit = function (fieldParameters) {

        var parametersToReturn = {};
        angular.forEach(fieldParameters, function(fieldParam) {
            if(fieldParam.scan_station_field_parameter == 'multiButtonList') {
                parametersToReturn = JSON.parse(fieldParam.parameter_value);
            }
        });

        return parametersToReturn;
    }

    return factory;
}

