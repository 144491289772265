import * as angular from 'angular';
import jQuery from "jquery";

declare const window: any;

"use strict";

angular.module('FieldModule')
    .controller('SubBlocController', SubBlocController);

// Injection of each class
SubBlocController.$inject = ['$rootScope', '$scope', 'ScopeAndLocal', 'SubBlocFieldFactory', '$window'];

// Controller Constructor
function SubBlocController($rootScope, $scope, ScopeAndLocal, SubBlocFieldFactory, $window) {

    let vm = this;

    vm.$onInit = async function () {
        try {
            ScopeAndLocal.startVariable(vm, $scope, 'action');
            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            ScopeAndLocal.startVariable(vm, $scope, 'data');
            ScopeAndLocal.startVariable(vm, $scope, 'fc');
            ScopeAndLocal.startVariable(vm, $scope, 'field');
            ScopeAndLocal.startVariable(vm, $scope, 'row');

        } catch (e) {
            console.log(e);
        }
    }

    /*
    This method will take data from the sub bloc, and populate fields in the parent bloc
    @param dataAndField
     */

    vm.sendChildDataToParent = async function (dataAndField) {
        if (vm.scanStation.showMoreSingleDataBox !== true) {
            angular.forEach(vm.scanStation.scanStationObj.scanStationFields, function (field) {
                if (field.field_type == 'formception-2' && field.subFormSendChildDataToParentFields != null) {
                    angular.forEach(field.subFormSendChildDataToParentFields, function (childToParentCombo) {
                        if (dataAndField.subBlocChildToParentField.id == childToParentCombo.childFieldId && vm.scanStation.scanStationFieldIdsToField[childToParentCombo.parentFieldId] != null) {
                            vm.scanStation.scanStationObj.dataToAdd[vm.scanStation.scanStationFieldIdsToField[childToParentCombo.parentFieldId].field_slug] = dataAndField.subBlocChildToParentData;
                            vm.scanStation.getRules('no-event', childToParentCombo.parentFieldId, vm.scanStation.scanStationFieldIdsToField[childToParentCombo.parentFieldId].special_field_key, dataAndField.subBlocChildToParentData, vm.scanStation.scanStationFieldIdsToField[childToParentCombo.parentFieldId].fieldIndex, vm.scanStation.scanStationFieldIdsToField[childToParentCombo.parentFieldId].field_slug);
                        }
                    });
                }
            });
        }
    }

    $window.addEventListener('message', async function (data) {
        try {
            if (data.data != null && data.data.subBlocChildToParentField != null) {
                await vm.sendChildDataToParent(data.data);
                $scope.$apply();
            }
            ;
        } catch (e) {
            console.log(e);
        }

    });

    $window.addEventListener('message', async function (field) {
        try {

            if (field.data.processAfterSubmitEvents == true) {
                await vm.scanStation.updateDropDownsRadiosAutoCompletesForAllFields();
            }
        } catch (e) {

        }
    });
}

export default SubBlocController;